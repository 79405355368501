import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Subscription
 */

/**
 * Query
 */

export const GET_WAREHOUSES = gql(`
  query GetWarehouses($paginationOffset: Float, $paginationLimit: Float, $filter: WarehouseFilterInput) {
    warehouses(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, filter: $filter) {
      ...warehouse
    }

    warehousesMeta(filter: $filter) {
      total
    }
  }
`)

export const GET_WAREHOUSE = gql(`
  query GetWarehouse($filter: WarehouseFilterInput) {
    warehouse(filter: $filter) {
      ...warehouse
    }
  }
`)

export const GET_WAREHOUSES_META = gql(`
  query GetWarehousesMeta($filter: WarehouseFilterInput) {
    warehousesMeta(filter: $filter) {
      total
    }
  }
`)

/**
 * Mutation
 */

export const CREATE_WAREHOUSE = gql(`
  mutation CreateWarehouse($payload: WarehouseInput!) {
    createWarehouse(payload: $payload) {
      ...warehouse
    }
  }
`)

export const UPDATE_WAREHOUSE = gql(`
  mutation UpdateWarehouse($payload: WarehouseInput!, $_id: String!) {
    updateWarehouse(payload: $payload, _id: $_id) {
      ...warehouse
    }
  }
`)

export const DELETE_WAREHOUSE = gql(`
  mutation DeleteWarehouse($_id: String!) {
    deleteWarehouse(_id: $_id) {
      ...warehouse
    }
  }
`)

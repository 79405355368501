import { gql } from 'graphql-generator/__generated__/gql'

/* ---------------------------------- Query --------------------------------- */
export const GET_BANK_ACCOUNTS = gql(`
  query Getbankaccounts(
    $filter: BankAccountFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getBankAccounts(
      filter: $filter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
      ...bankaccounts
    }
  }
`)

export const GET_BANK_ACCOUNTS_META = gql(`
  query BankAccountsMeta {
    bankAccountsMeta {
      all
      pay_account
      supplier_account
      sales_account
    }
  }
`)

/* -------------------------------- Mutation -------------------------------- */
// create
export const CREATE_BANK_ACCOUNT = gql(`
  mutation CreateBankAccount($input: BankAccountCreateInput!) {
    createBankAccount(input: $input) {
      ...bankaccounts
    }
  }
`)

// update
export const UPDATE_BANK_ACCOUNT = gql(`
  mutation UpdateBankAccount($input: UpdateBankAccountInput!) {
    updateBankAccount(input: $input) {
      ...bankaccounts
    }
  }
`)

export const UPDATE_BANK_ACCOUNTS = gql(`
  mutation UpdateBankAccounts($input: [UpdateBankAccountInput!]!) {
    updateBankAccounts(input: $input) {
      ...bankaccounts
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

/* -------------------------------------------------------------------------- */
/*                                    Query                                   */
/* -------------------------------------------------------------------------- */

export const GET_SUPPLIER_PROFILE_LIST = gql(`
  query GetSupplierProfileList(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $advancedSearchFilter: SupplierProfileAdvancedSearchFilterInput
  ) {
    getSupplierProfileList(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      advancedSearchFilter: $advancedSearchFilter
    ) {
      data {
        ...leanSupplierProfile
      }
      meta {
        all
      }
    }
  }
`)

export const GET_LEAN_SUPPLIER_PROFILES = gql(`
  query GetLeanSupplierProfile(
    $filter: SupplierProfileFilterInput
  ) {
    leanSupplierProfile(filter: $filter) {
      ...supplierProfile
    }
  }
`)

export const GET_SUPPLIER_PROFILE = gql(`
  query GetSupplierProfile($filter: SupplierProfileFilter!) {
    getSupplierProfile(filter: $filter) {
      ...supplierProfile
    }
  }
`)

/* -------------------------------------------------------------------------- */
/*                                  Mutation                                  */
/* -------------------------------------------------------------------------- */

export const CREATE_SUPPLIER_PROFILE = gql(`
  mutation CreateSupplierProfile($input: SupplierProfileInput!) {
    createSupplierProfile(input: $input) {
      ...supplierProfile
    }
  }
`)

/* ---------------------------------- Items --------------------------------- */
export const UPDATE_SUPPLIER_PROFILE_ITEMS = gql(`
  mutation UpdateSupplierProfileItems($input: UpdateSupplierProfileInput!) {
    updateSupplierProfileItems(input: $input) {
      ...supplierProfile
    }
  }
`)

/* --------------------------------- Address -------------------------------- */
export const UPSERT_SUPPLIER_PROFILE_ADDRESS = gql(`
  mutation UpsertSupplierProfileAddress($input: UpdateSupplierProfileInput!) {
    upsertSupplierProfileAddress(input: $input) {
      ...supplierProfile
    }
  }
`)

export const UPDATE_DEFAULT_SUPPLIER_PROFILE = gql(`
  mutation UpdateDefaultSupplierProfile($input: UpdateDefaultSupplierProfileInput!) {
    updateDefaultSupplierProfile(input: $input) {
      ...supplierProfile
    }
  }
`)

export const DELETE_SUPPLIER_PROFILE_ADDRESS = gql(`
  mutation DeleteSupplierProfileAddress($input: DeleteSupplierProfileAddressInput!) {
    deleteSupplierProfileAddress(input: $input) {
      success
    }
  }
`)

export const UPDATE_SUPPLIER_PROFILE = gql(`
  mutation UpdateSupplierProfile($input: UpdateSupplierProfileInput!) {
    updateSupplierProfile(input: $input) {
      ...supplierProfile
    }
  }
`)

/* ---------------------------------- Bank ---------------------------------- */
//change name
export const UPSERT_SUPPLIER_PROFILE_BANK_ACCOUNT = gql(` 
  mutation UpsertSupplierProfileBankAccount($input: UpdateSupplierProfileInput!) {
    upsertSupplierProfileBankAccount(input: $input) {
      ...supplierProfile
    }
  }
`)

export const UPDATE_DEFAULT_SUPPLIER_PROFILE_BANK_ACCOUNT = gql(`
  mutation UpdateDefaultSupplierProfileBankAccount($input: UpdateDefaultSupplierProfileInput!) {
    updateDefaultSupplierProfileBankAccount(input: $input) {
      ...supplierProfile
    }
  }
`)

// export const DELETE_CUSTOMER_PROFILE = gql(`
//   mutation DeleteCustomerProfile($customerId: String!) {
//     deleteCustomerProfile(customerId: $customerId) {
//       ...customerprofile
//     }
//   }
// `)

export const GET_SUPPLIER_NAMES = gql(`
  query GetSupplierNames($filter: GetSupplierProfileNameFilter) {
    getSupplierNames(filter: $filter) {
      _id
     supplierId
     supplierName
    }
  }
`)

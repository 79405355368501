import { gql } from 'graphql-generator/__generated__'

/* -------------------------------------------------------------------------- */
/*                                    Query                                   */
/* -------------------------------------------------------------------------- */

export const GET_PURCHASE_REQUISITION_META = gql(`
  query GetPurchaseRequisitionMeta($filter: PurchaseRequisitionDetailsAdvancedSearchFilterInput!) {
    purchaseRequisitionMeta(filter: $filter) {
      all
      open
      approved
      rejected
      returned
      request_purchase_confirm
      request_super_approval
    }
  }
`)

export const GET_LEAN_PURCHASE_REQUISITIONS_ADVANCED_SEARCH = gql(`
  query GetLeanPurchaseRequisitionsAdvancedSearch(
    $advancedSearchFilter: PurchaseRequisitionAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getPurchaseRequisitionList(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      ...leanPurchaseRequisition
    }
  }

`)

export const GET_PURCHASE_REQUISITION = gql(`
  query GetPurchaseRequisition($filter: PurchaseRequisitionFilter!) {
    getPurchaseRequisition(filter: $filter) {
      ...purchaseRequisition
    }
  }
`)

export const GET_PRODUCTS_BY_PR_WITH_SUPPLIERS = gql(`
  query GetProductsByPrWithSuppliers($filter: PurchaseRequisitionFilter,$supplierInput: SupplierIdStringInput) {
    getProductsByPrWithSuppliers(filter: $filter, supplierInput: $supplierInput) {
      ...productsByPrWithSuppliers
    }
  }
`)

/* -------------------------------------------------------------------------- */
/*                                  Mutation                                  */
/* -------------------------------------------------------------------------- */
export const CREATE_PURCHASE_REQUISITION = gql(`
  mutation CreatePurchaseRequisition($input: PurchaseRequisitionInput!) {
    createPurchaseRequisition(input: $input) {
      ...purchaseRequisition
    }
  }

`)

export const UPDATE_PURCHASE_REQUISITION = gql(`
  mutation UpdatePurchaseRequisition($input: PurchaseRequisitionInput!) {
    updatePurchaseRequisitionDetails(input: $input) {
      ...purchaseRequisition
    }
  }

`)

export const UPDATE_PURCHASE_REQUISITION_STATUS = gql(`
  mutation UpdatePurchaseRequisitionStatus($input: UpdatePurchaseRequisitionStatusInput!) {
    updatePurchaseRequisitionStatus(input: $input) {
      ...purchaseRequisition
    }
  }

`)

export const CREATE_PO_FROM_PR = gql(`
  mutation CreatePurchaseOrderOldFromPurchaseRequisition($input: PurchaseOrderOldInput!) {
    createPurchaseOrderOldFromPurchaseRequisition(input: $input){
      _id
      attachments
      createdAt
      createdById
      currencyDiff
      deliveryChannelId
      deliveryDate
      estimateReceivedDateFrom
      estimateReceivedDateTo
      foreignCurrency
      isForeignCurrency
      note
      purchaseOrderId
      purchaseRequisitionId
      rejectReasonNote
      sendBackReasonNote
      status
      supplierId
      supplierName
      updatedAt
      warehouseId
      workspaceId
    }
  }
`)

// export const UPDATE_CUSTOMER_PROFILE = gql(`
//   mutation UpdateCustomerProfile($input: UpdateCustomerProfileInput!) {
//     updateCustomerProfile(input: $input) {
//       ...customerprofile
//     }
//   }
//
// `
// export const UPDATE_DEFAULT_CUSTOMER_PROFILE = gql(`
//   mutation UpdateDefaultCustomerProfile($input: UpdateDefaultCustomerProfileInput!) {
//     updateDefaultCustomerProfile(input: $input) {
//       ...customerprofile
//     }
//   }
//
// `

// export const UPDATE_CUSTOMER_PROFILE_ADDRESS = gql(`
//   mutation DeleteCustomerProfileAddress($input: DeleteCustomerProfileAddress!) {
//     deleteCustomerProfileAddress(input: $input) {
//       ...customerprofile
//     }
//   }
//
// `
// export const DELETE_CUSTOMER_PROFILE = gql(`
//   mutation DeleteCustomerProfile($customerId: String!) {
//     deleteCustomerProfile(customerId: $customerId) {
//       ...customerprofile
//     }
//   }
//
// `

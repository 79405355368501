import { gql } from 'graphql-generator/__generated__/gql'

// /**
//  * Subscription
//  */

// export const LEAN_ORDER_SUBSCRIPTION = gql(`
//   subscription LeanOrderSubscription(
//     $operationTypes: [String!]
//     $options: SubscriptionOptions
//     $filter: OrderFilterInput
//     $advancedSearchFilter: OrderAdvancedSearchFilterInput
//   ) {
//     leanOrderSubscription(
//       operationTypes: $operationTypes
//       options: $options
//       filter: $filter
//       advancedSearchFilter: $advancedSearchFilter
//     ) {
//       operation
//       orderId
//       order {
//         ...leanOrder
//       }
//       updatedFields
//     }
//   }
// `)

/**
 * Query
 */

export const GET_BOOTH_EVENTS = gql(`
  query GetBoothEvents(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: BoothEventFilterInput
  ) {
    getBoothEvents(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...boothEvent
    }
  }
`)

export const GET_LEAN_BOOTH_EVENTS = gql(`
  query GetLeanBoothEvents(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: BoothEventFilterInput
  ) {
    getLeanBoothEvents(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...leanBoothEvent
    }
  }
`)

export const GET_BOOTH_EVENT = gql(`
  query GetBoothEvent(
    $filter: BoothEventFilterInput
  ) {
    getBoothEvent(
      filter: $filter
    ) {
      ...boothEvent
    }
  }
`)

// /**
//  * Mutation
//  */

export const CREATE_BOOTH_EVENT = gql(`
	mutation CreateBoothEvent($input: BoothEventInput!) {
		createBoothEvent(input: $input) {
			...boothEvent
		}
	}
`)

export const UPDATE_BOOTH_EVENT = gql(`
  mutation UpdateBoothEvent($id: String!, $input: BoothEventInput!) {
    updateBoothEvent(id: $id, input: $input) {
      ...boothEvent
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

// orderEvidenceMany
export const GET_ORDER_EVIDENCE_MANY = gql(`
  query GetOrderEvidenceMany(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: OrderEvidenceFilterInput
  ) {
    orderEvidenceMany(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      orderEvidenceId
      driverName
      licensePlate
      deliveryChannel {
        name
      }
      identifiers
      note
      _id
      createdAt
      updatedAt
    }
  }
`)
export const GET_ORDER_EVIDENCE_MANY_META = gql(`
  query GetOrderEvidenceManyMeta($filter: OrderEvidenceFilterInput) {
    orderEvidenceMeta(filter: $filter) {
      total
    }
  }
`)

export const GET_ORDER_EVIDENCE = gql(`
  query GetOrderEvidence($params: OrderEvidenceFilterInput) {
    orderEvidence(params: $params) {
      orderEvidenceId
      driverName
      licensePlate
      deliveryChannel {
        name
      }
      identifiers
      note
      _id
      createdAt
      updatedAt
      createdBy {
        displayName
      }
    }
  }
`)

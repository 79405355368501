import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Order Logs
 */

export const GET_AUDIT_LOGS = gql(`
  query GetAuditLogs(
    $filter: AuditLogFilterInput
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
  ) {
    auditLogs(filter: $filter, paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
      ...auditLog
    }
  }
`)

export const GET_AUDIT_LOGS_META = gql(`
  query GetAuditLogsMeta($filter: AuditLogFilterInput) {
    auditLogsMeta(filter: $filter) {
      total
    }
  }
`)

export const GET_AUDIT_LOGS_WITH_META = gql(`
  query GetAuditLogsWithMeta(
    $filter: AuditLogFilterInput
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
  ) {
    auditLogs(filter: $filter, paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
      ...auditLog
    }

    auditLogsMeta(filter: $filter) {
      total
    }
  }
`)

/**
 * Sync Logs
 */

export const LAST_SYNC_INVENTORY_LOG = gql(`
  query LastSyncInventoryLog($productId: String!) {
    lastSyncInventoryLog(productId: $productId) {
      shopee {
        lastSyncTime
        status
      }
      lazada {
        lastSyncTime
        status
      }
    }
  }
`)

export const LAST_SYNC_SUMMARY_LOGS = gql(`
  query LastSyncSummaryLog {
    lastSyncSummaryLog {
      shopee {
        lastSyncTime
        status
      }
      lazada {
        lastSyncTime
        status
      }
    }
  }
`)

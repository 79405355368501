import { gql } from 'graphql-generator/__generated__'

export const GET_ONE_TECHNICAL_DATA_SHEET_WITH_INPUT_DATA_BY_ID = gql(`
    query GetOneTechnicalDataSheetWithInputsData($filter: TechnicalDataSheetFilterInput) {
        getOneTechnicalDataSheetWithInputsData(filter: $filter) {
            ...technicalDataSheetWithInputsData
        }
    }
`)

export const GET_MANY_TECHNICAL_DATA_SHEETS = gql(`
    query GetManyTechnicalDataSheets($filter: TechnicalDataSheetFilterInput, $sort: SortInput, $paginationLimit: Int, $paginationOffset: Int) {
        getManyTechnicalDataSheets(filter: $filter, sort: $sort, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset) {
            ...technicalDataSheet
        }
    }
`)

export const GET_MANY_TECHNICAL_DATA_SHEETS_LATEST_ONLY = gql(`
    query GetManyTechnicalDataSheetsLatestOnly($filter: TechnicalDataSheetFilterInput, $sort: SortInput, $paginationLimit: Int, $paginationOffset: Int) {
        getManyTechnicalDataSheetLatestOnly(filter: $filter, sort: $sort, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset) {
            ...technicalDataSheet
        }
    }
`)

export const GET_MANY_TECHNICAL_DATA_SHEETS_LATEST_ONLY_FOR_BULK_CREATE = gql(`
    query GetManyTechnicalDataSheetsLatestOnlyForBulkCreate($filter: TechnicalDataSheetFilterInput, $sort: SortInput, $paginationLimit: Int, $paginationOffset: Int) {
        getManyTechnicalDataSheetLatestOnlyForBulkCreate(filter: $filter, sort: $sort, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset) {
            ...technicalDataSheetForBulkCreate
        }
    }
`)

export const GET_MANY_TECHNICAL_DATA_SHEETS_META_LATEST_ONLY = gql(`
    query GetManyTechnicalDataSheetMetaLatestOnly($filter: TechnicalDataSheetFilterInput) {
        getManyTechnicalDataSheetMetaLatestOnly(filter: $filter) {
            latestTDSCount
        }
    }
`)

export const GET_MANY_TECHNICAL_DATA_SHEETS_LATEST_ONLY_WITH_INPUTS_DATA = gql(`
    query GetManyTechnicalDataSheetsLatestOnlyWithInputsData($filter: TechnicalDataSheetFilterInput, $sort: SortInput, $paginationLimit: Int, $paginationOffset: Int) {
        getManyTechnicalDataSheetLatestOnlyWithInputsData(filter: $filter, sort: $sort, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset) {
            ...technicalDataSheetWithInputsData
        }
    }
`)

export const GET_TECHNICAL_DATA_SHEET_PRODUCT = gql(`
    query GetTechnicalDataSheetProduct($sku: StringFilterInput!, $filter: ProductFilterInput) {
        getTechnicalDataSheetProduct(sku: $sku, filter: $filter) {
                ...technicalDataSheetProduct
        }
    }
`)

export const GET_PRODUCT_COST_HISTORIES = gql(`
    query GetProductCostHistories($sku: StringFilterInput!, $paginationLimit: Int, $paginationOffset: Int) {
        getProductCostHistories(sku: $sku, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset) {
                ...technicalDataSheetProductHistories
        }
    }
`)

export const CREATE_ONE_TECHNICAL_DATA_SHEET = gql(`
    mutation CreateOneTechnicalDataSheet($input: CreateTechnicalDataSheetInput!, $option: CreateTechnicalDataSheetOptionInput) {
        createOneTechnicalDataSheet(input: $input, option: $option) {
            tdsId

            product {
            _id
            sku
            name
            type
            images {
                imageType
                src
                order
            }
            pricing {
                originalPrice
            }
            }
            inputs {
            materials {
                tds {
                tdsId
                }
                amount
            }
            stations {
                ...station
            }
            }
            productCost {
                totalMaterialCost
                totalLaborCost
            }

            totalCost
            tdsMeta {
            height {
                value
                unit
            }
            length {
                value
                unit
            }
            depth {
                value
                unit
            }
            quantityUnique
            }
            note
            latest
            cancelled
            updatedAt
        }
    }
`)

export const CREATE_MANY_TECHNICAL_DATA_SHEETS = gql(`
    mutation CreateManyTechnicalDataSheets($inputs: [CreateTechnicalDataSheetInput!]!, $option: CreateTechnicalDataSheetOptionInput) {
        createManyTechnicalDataSheets(inputs: $inputs, option:  $option) {
            tdsId
        }
    }
`)

export const CANCEL_ONE_TECHNICAL_DATA_SHEET = gql(`
    mutation CancelOneTechnicalDataSheet($input: CancelFilterInput!) {
        cancelOneTechnicalDataSheet(input: $input)
    }
`)

import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_MANY_STOCK_MOVEMENT = gql(`
  query GetManyStockMovement(
      $filter: StockMovementFilterInput
      $sort: SortInput
      $paginationOffset: Float
      $paginationLimit: Float
    ) {
      getManyStockMovement(
      filter: $filter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
      ...stockMovement
    }
    }
`)

export const GET_MANY_STOCK_MOVEMENT_WITH_META = gql(`
  query GetManyStockMovementWithMeta(
      $filter: StockMovementFilterInput
      $sort: SortInput
      $paginationOffset: Float
      $paginationLimit: Float
    ) {
      getManyStockMovementWithMeta(
      filter: $filter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
        data {
          ...stockMovement
        }
        meta {
          all
        }
    }
  }
`)

export const GET_MANY_IN_OUT_STOCK = gql(`
  query GetManyInOutStock($filter: StockMovementFilterInput, $paginationOffset: Float, $paginationLimit: Float, $groupBy: String) {
    getManyInOutStock(filter: $filter, paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, groupBy: $groupBy) {
      ...stockInOut
    }
  }
`)

export const GET_MANY_IN_OUT_STOCK_WITH_META = gql(`
   query GetManyInOutStockWithMeta(
      $filter: StockMovementFilterInput
      $paginationOffset: Float
      $paginationLimit: Float
      $groupBy: String
      $groupByCoversionFactor: Boolean
    ) {
      getManyInOutStockWithMeta(
        filter: $filter
        paginationOffset: $paginationOffset
        paginationLimit: $paginationLimit
        groupBy: $groupBy
        groupByCoversionFactor: $groupByCoversionFactor
      ) {
        data {
          ...stockInOut
        }
        meta {
          all
        }
    }
  }
`)

export const GET_STOCK_STATISTIC = gql(`
  query GetStockStatistic(
    $filter: StockMovementFilterInput
  ) {
    getStockStatistic(
      filter: $filter
    ) {
      totalIn
      totalOut
      totalOrder
      totalInventory
      totalSku
    }
  }
`)

export const GET_MANY_IN_OUT_STOCK_CHART = gql(`
   query GetManyInOutStockChart(
      $filter: StockMovementFilterInput
    ) {
      getManyInOutStockChart(
        filter: $filter
      ) {
          totalIn
          totalOut
          date
          documents {
            key
            value
            type
          }
    }
  }
`)

export const GET_MANY_QUANTITY_STOCK_CHART = gql(`
  query GetManyQuantityStockChart($filter: StockMovementFilterInput) {
    getManyQuantityStockChart(filter: $filter) {
      date
      quantity
      warehouse {
        _id 
        name
      }
    }
  }
`)

export const GET_MANY_CATEGORY_STOCK_CHART = gql(`
  query GetManyCategoryStockChart($filter: StockMovementFilterInput) {
    getManyCategoryStockChart(filter: $filter) {
      category
      products {
        totalIn
        totalOut
        productSku
      }
      totalInPercent
      totalOutPercent
      color
    }
  }
`)

export const COUNT_STOCK_MOVEMENT = gql(`
  query CountStockMovement($filter: StockMovementFilterInput) {
    countStockMovement(filter: $filter)
  }
`)

export const GET_MANY_STOCK_MOVEMENT_DETAIL_WITH_META = gql(`
  query GetManyStockMovementDetailWithMeta(
    $filter: StockMovementFilterInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getManyStockMovementDetailWithMeta(
      filter: $filter
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
      data {
        totalIn
        totalOut
        totalInSubUnit
        totalOutSubUnit
        date
        unit
        conversionUnit
        conversionFactor
        subtype
        quantity
        quantityInSubUnit
        product {
          _id
          name
          sku
          images {
            src
            imageType
            order
          }
          status
        }
      }
      meta {
          all
      }
    }
  }
`)

export const GET_STOCK_MOVEMENTS_AND_RELATED_DOCUMENT = gql(`
  query GetStockMovementsAndRelatedDocument($docId: String!) {
    getStockMovementsAndRelatedDocument(docId: $docId) {
      productSku
      productName
      warehouse
      stockQuantity
      documentQuantity
      isQtyMatch
      type
      docType
    }
  }
`)

/**
 * Mutation
 */

export const COPY_STOCK_MOVEMENT = gql(`
  mutation CopyStockMovement($filter: StockMovementFilterInput, $columns: [String!]!) {
    copyStockMovement(filter: $filter, columns: $columns)
  }
`)

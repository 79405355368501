import { gql } from 'graphql-generator/__generated__/gql'

/* -------------------------------------------------------------------------- */
/*                                    Query                                   */
/* -------------------------------------------------------------------------- */

export const GET_ORDERS_RETURN_LISTS_META = gql(`
  query GetOrderReturnListsMeta($filter: OrderReturnFilterInput!) {
    orderReturnListsMeta(filter: $filter) {
      all
      open
      in_progress
      cancelled
      done
    }
  }
`)

export const GET_ORDER_RETURN_LISTS = gql(`
  query GetOrderReturnLists(
    $filter: OrderReturnFilterInput
    $paginationLimit: Float
    $paginationOffset: Float
    $sort: SortInput
  ) {
    orderReturnLists(
      filter: $filter
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
    ) {
      ...orderReturnFragment
    }
  }
`)

export const GET_ORDER_RETURN = gql(`
  query GetOrderReturn($filter: OrderReturnFilter!) {
    getOrderReturn(filter: $filter) {
      ...orderReturnFragment
    }
  }
`)

/* -------------------------------------------------------------------------- */
/*                                  Mutation                                  */
/* -------------------------------------------------------------------------- */
export const CREATE_ORDER_RETURN = gql(`
  mutation CreateOrderReturn($input: OrderReturnFlowInput!) {
    createOrderReturn(input: $input) {
      ...orderReturnFragment
    }
  }
`)

export const UPDATE_ORDER_RETURN_ITEMS_STATUS = gql(`
  mutation UpdateOrderReturnItemsStatus($input: OrderReturnItemsStatusInput!) {
    updateOrderReturnItemsStatus(input: $input) {
      ...orderReturnFragment
    }
  }
`)

export const UPDATE_ORDER_RETURN_ITEMS = gql(`
  mutation UpdateOrderReturnItems($input: UpdateOrderReturnInput!) {
    updateOrderReturnItems(input: $input) {
      ...orderReturnFragment
    }
  }
`)

export const CANCEL_ORDER_RETURN = gql(`
  mutation CancelOrderReturn($id: String!) {
    cancelOrderReturn(id: $id) {
      ...orderReturnFragment
    }
  }
`)

export const DELETE_ORDER_RETURN = gql(`
  mutation DeleteOrderReturn($id: String!) {
    deleteOrderReturn(id: $id) {
      ...orderReturnFragment
    }
  }
`)

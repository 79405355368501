import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_RESERVE_ORDERS = gql(`
  query GetReserveOrders(
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
    $filter: ReserveOrderFilterInput
  ) {
    reserveOrders(
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      filter: $filter
    ) {
      ...leanReserveOrder
    }
  }
`)

export const GET_RESERVE_ORDERS_META = gql(`
  query GetReserveOrdersMeta($filter: ReserveOrderFilterInput) {
    reserveOrdersMeta(filter: $filter) {
      all
      waitApproval
      approved
      expired
      cancelled
    }
  }
`)

export const GET_RESERVE_ORDER = gql(`
  query GetReserveOrder($id: String!) {
    reserveOrder(id: $id) {
      ...reserveOrder
    }
  }
`)

/**
 * Mutation
 */

export const CREATE_RESERVE_ORDER = gql(`
  mutation CreateReserveOrder($input: ReserveOrderInput!) {
    createReserveOrder(input: $input) {
      ...reserveOrder
    }
  }
`)

export const UPDATE_RESERVE_ORDER_STATUS = gql(`
  mutation UpdateReserveOrderStatus($newStatus: String!, $id: String!) {
    updateReserveOrderStatus(newStatus: $newStatus, id: $id) {
      ...reserveOrder
    }
  }
`)

export const APPROVE_RESERVE_ORDER = gql(`
  mutation ApproveReserveOrder($orderId: String!) {
    approveReserveOrder(orderId: $orderId) {
      ...reserveOrder
    }
  }
`)

export const DELETE_RESERVE_ORDER = gql(`
  mutation DeleteReserveOrder($id: String!) {
    deleteReserveOrder(id: $id) {
      ...reserveOrder
    }
  }
`)

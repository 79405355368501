import { gql } from 'graphql-generator/__generated__/gql'

export const GET_USERS_AUTH0 = gql(`
  query GetUsersAuth0 {
    getUsersAuth0 {
      ...userAuth0
    }
  }
`)

export const DELETE_USER_AUTH0 = gql(`
  mutation DeleteUserAuth0($uid: String!) {
    deleteUserAuth0(uid: $uid) {
      uid
    }
  }
`)

export const INVITE_USER_BY_EMAIL_AUTH0 = gql(`
  mutation InviteUserByEmailAuth0($input: InviteUserByEmailInput!) {
    inviteUserByEmailAuth0(input: $input) {
      ...userAuth0
    }
  }
`)

import { gql } from 'graphql-generator/__generated__'

// ====================================================================================================================
// =                                               Purchase Order New                                                 =
// ====================================================================================================================
export const GET_PURCHASE_ORDERS = gql(`
  query GetPurchaseOrders($filter: PurchaseOrderFilterInput!, $sort: SortInput, $paginationOffset: Float, $paginationLimit: Float) {
      getPurchaseOrders(filter: $filter, sort: $sort, paginationOffset: $paginationOffset, paginationLimit: $paginationLimit) {
        ...purchaseOrderResponse
      }
  }
`)

export const GET_PURCHASE_ORDER = gql(`
  query GetPurchaseOrder($filter: PurchaseOrderFilterInput!) {
    getPurchaseOrder(filter: $filter) {
      ...purchaseOrderResponse
    }
  }
`)

export const GET_PURCHASE_ORDER_META = gql(`
  query GetPurchaseOrderMeta($filter: PurchaseOrderFilterInput!) {
    purchaseOrderMeta(filter: $filter) {
      all
      wait_for_approve
      not_approved
      return_to_edit
      approved
    }
  }
`)

export const CREATE_PURCHASE_ORDER = gql(`
  mutation CreatePurchaseOrder($data: CreatePurchaseOrderInput!) {
    createPurchaseOrder(data: $data) {
      purchaseOrderId
    }
  }
`)

export const UPDATE_PURCHASE_ORDER_STATUS = gql(`
  mutation UpdatePurchaseOrderStatus($input: UpdatePurchaseOrderStatusInput!) {
    updatePurchaseOrderStatus(input: $input) {
      purchaseOrderId
    }
  }
`)

/**
 * **Warning**: For all nested objects and arrays, you need to pass all the data in the input.
 *
 */
export const UPDATE_PURCHASE_ORDER = gql(`
  mutation UpdatePurchaseOrder($input: UpdatePurchaseOrderInput!) {
    updatePurchaseOrder(input: $input) {
      purchaseOrderId
    }
  }
`)
// ====================================================================================================================

/* -------------------------------------------------------------------------- */
/*                                    Query                                   */
/* -------------------------------------------------------------------------- */

export const GET_PURCHASE_ORDERS_OLD = gql(`
  query GetPurchaseOrderOlds($filter: PurchaseOrderOldFilter!, $sort: SortInput, $paginationOffset: Float, $paginationLimit: Float) {
    getPurchaseOrderOlds(filter: $filter, sort: $sort, paginationOffset: $paginationOffset, paginationLimit: $paginationLimit) {
    ...purchaseOrderOld
   }
  }
`)

export const GET_PURCHASE_ORDER_OLD_META = gql(`
  query GetPurchaseOrderOldMeta($filter: PurchaseOrderOldAdvancedSearchFilterInput!) {
    purchaseOrderOldMeta(filter: $filter) {
       all
       approved
       open
       paid
       received_correct
       received_incorrect
       rejected
       returned
       request_super_approval
       purchased
       documents_missing
    }
  }
`)

export const GET_LEAN_PURCHASE_ORDER_OLD_ADVANCED_SEARCH = gql(`
  query GetLeanPurchaseOrderOldsAdvancedSearch(
    $advancedSearchFilter: PurchaseOrderOldAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getPurchaseOrderOldList(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      ...leanPurchaseOrderOld
      department {
        depCode
        key
        name
    }
      estimateReceivedDateFrom
      estimateReceivedDateTo
    }
  }
  
`)

export const GET_PURE_PURCHASE_ORDER_OLD = gql(`
  query GetPurePurchaseOrderOlds(
    $advancedSearchFilter: PurchaseOrderOldAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getPurchaseOrderOldList(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      _id
      purchaseOrderId
      purchaseRequisitionId
      items {
        productId
        productName
        productSku
      }
      supplierId
      supplierName
      warehouse {
        _id
      }
    }
  }
`)

export const GET_PURCHASE_ORDER_OLD = gql(`
  query GetPurchaseOrderOld($filter: PurchaseOrderOldFilter!) {
    getPurchaseOrderOld(filter: $filter) {
      ...purchaseOrderOld
    }
  }
  
`)

export const GET_PO_LOCATIONS = gql(`
  query PoLocations($filter: PoLocationFilterInput!) {
    poLocations(filter: $filter) {
      _id
      key
      name
    }
  }
`)

/* -------------------------------------------------------------------------- */
/*                                  Mutation                                  */
/* -------------------------------------------------------------------------- */
export const CREATE_PURCHASE_ORDER_OLD = gql(`
  mutation CreatePurchaseOrderOld($input: PurchaseOrderOldInput!) {
    createPurchaseOrderOld(input: $input) {
      ...purchaseOrderOld
    }
  }
  
`)
export const UPDATE_PURCHASE_ORDER_OLD = gql(`
  mutation UpdatePurchaseOrderOld($input: PurchaseOrderOldInput!) {
    updatePurchaseOrderOldDetails(input: $input) {
      ...purchaseOrderOld
    }
  }
  
`)

export const UPDATE_PURCHASE_ORDER_OLD_STATUS = gql(`
  mutation UpdatePurchaseOrderOldStatus($input: UpdatePurchaseOrderOldStatusInput!) {
    updatePurchaseOrderOldStatus(input: $input) {
      ...purchaseOrderOld
    }
  }
  
`)

export const UPDATE_PURCHASE_ORDER_OLD_ATTACHMENTS = gql(`
  mutation UpdatePurchaseOrderOldAttachments($input: UpdatePurchaseOrderOldAttachmentsInput!) {
    updatePurchaseOrderOldAttachments(input: $input) {
      ...purchaseOrderOld
    }
  }
  
`)
export const UPDATE_PURCHASE_ORDER_OLD_RECEIVED = gql(`
  mutation UpdatePurchaseOrderOldReceived($input: UpdatePurchaseOrderOldReceivedInput!) {
    updatePurchaseOrderOldReceived(input: $input) {
      ...purchaseOrderOld
    }
  }
`)

// export const UPDATE_CUSTOMER_PROFILE = gql(`
//   mutation UpdateCustomerProfile($input: UpdateCustomerProfileInput!) {
//     updateCustomerProfile(input: $input) {
//       ...customerprofile
//     }
//   }
//
// `
// export const UPDATE_DEFAULT_CUSTOMER_PROFILE = gql(`
//   mutation UpdateDefaultCustomerProfile($input: UpdateDefaultCustomerProfileInput!) {
//     updateDefaultCustomerProfile(input: $input) {
//       ...customerprofile
//     }
//   }
//
// `

// export const UPDATE_CUSTOMER_PROFILE_ADDRESS = gql(`
//   mutation DeleteCustomerProfileAddress($input: DeleteCustomerProfileAddress!) {
//     deleteCustomerProfileAddress(input: $input) {
//       ...customerprofile
//     }
//   }
//
// `
// export const DELETE_CUSTOMER_PROFILE = gql(`
//   mutation DeleteCustomerProfile($customerId: String!) {
//     deleteCustomerProfile(customerId: $customerId) {
//       ...customerprofile
//     }
//   }
//
// `

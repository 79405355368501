import { gql } from 'graphql-generator/__generated__/gql'

export const CHECK_KERRY_TRACKING_NUMBERS = gql(`
  query CheckKerryTrackingNumbers($trackingNumbers: [String!]!) {
    checkTrackingNumbers(trackingNumbers: $trackingNumbers) {
     inputLength
     outputLength
     list {
      trackingNo
      refNo
      statusDatetime
      statusDesc
     }
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

/* -------------------------------------------------------------------------- */
/*                                    Query                                   */
/* -------------------------------------------------------------------------- */

export const GET_CUSTOMER_PROFILE_LIST = gql(`
  query GetCustomerProfileList(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $advancedSearchFilter: CustomerProfileAdvancedSearchFilterInput
  ) {
    getCustomerProfileList(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      advancedSearchFilter: $advancedSearchFilter
    ) {
      data {
        ...customerprofile
      }
      meta {
        all
      }
    }
  }
`)

export const GET_CUSTOMER_PROFILE = gql(`
  query GetCustomerProfile($filter: CustomerProfileFilter!) {
    getCustomerProfile(filter: $filter) {
      ...customerprofile
    }
  }
`)

/* -------------------------------------------------------------------------- */
/*                                  Mutation                                  */
/* -------------------------------------------------------------------------- */
export const CREATE_CUSTOMER_PROFILE = gql(`
  mutation CreateCustomerProfile($input: CustomerProfileInput!) {
    createCustomerProfile(input: $input) {
      ...customerprofile
    }
  }
`)
export const UPDATE_CUSTOMER_PROFILE = gql(`
  mutation UpdateCustomerProfile($input: UpdateCustomerProfileInput!) {
    updateCustomerProfile(input: $input) {
      ...customerprofile
    }
  }
`)
export const UPDATE_DEFAULT_CUSTOMER_PROFILE = gql(`
  mutation UpdateDefaultCustomerProfile($input: UpdateDefaultCustomerProfileInput!) {
    updateDefaultCustomerProfile(input: $input) {
      ...customerprofile
    }
  }
`)

export const UPDATE_CUSTOMER_PROFILE_ADDRESS = gql(`
  mutation DeleteCustomerProfileAddress($input: DeleteCustomerProfileAddress!) {
    deleteCustomerProfileAddress(input: $input) {
      ...customerprofile
    }
  }
`)
export const DELETE_CUSTOMER_PROFILE = gql(`
  mutation DeleteCustomerProfile($customerId: String!) {
    deleteCustomerProfile(customerId: $customerId) {
      ...customerprofile
    }
  }
`)

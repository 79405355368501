import { gql } from 'graphql-generator/__generated__'

export const GET_BANK_TRANSACTIONS = gql(`
  query GetBankTransactions(
    $filter: BackTransactionFilterInput!
    $sorts: [SortInput!]= []
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    findBankTransactions(
      filter: $filter
      sorts: $sorts
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
      ...bankTransaction
    }
  }
`)

export const GET_BANK_TRANSACTIONS_META = gql(`
  query GetBankTransactionsMeta(
    $filter: BackTransactionFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    findBankTransactionMeta(
      filter: $filter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
      ...bankTransactionMeta
    }
  }
`)

export const GET_LATEST_BANK_TRANSACTION = gql(`
  query GetLatestBankTransaction( $filter: BackTransactionFilterInput!) {
    findLatestBankTransaction(filter: $filter) {
      ...leanBankTransaction
    }
  }
`)

export const GET_TOTAL_BANK_TRANSACTION_BALANCE = gql(`
  query GetTotalBankTransactionBalance {
    findTotalBankTransaction {
      ...bankTransactionTotalBalance
    }
  }
`)

export const CREATE_BANK_TRANSACTION = gql(`
  mutation CreateBankTransaction(
    $input: CreateBankTransactionInput!
  ) {
    createBankTransaction(
      input: $input
    ) {
      ...bankTransaction
    }
  }
`)
export const CREATE_MANY_BANK_TRANSACTION = gql(`
  mutation CreateManyBankTransactions(
    $input: CreateManyBankTransactionInput!
  ) {
    createManyBankTransactions(
      input: $input
    ) {
      ...bankTransaction
    }
  }
`)

export const UPDATE_BANK_TRANSACTION = gql(`
  mutation UpdateBankTransaction(
    $input: UpdateBankTransactionInput!
  ) {
    updateBankTransaction(
      input: $input
    ) {
      ...bankTransaction
    }
  }
`)

export const UPDATE_MANY_BANK_TRANSACTION = gql(`
  mutation UpdateManyBankTransaction(
    $input: UpdateManyBankTransactionInput!
  ) {
    updateManyBankTransaction(
      input: $input
    ) {
      ...bankTransaction
    }
  }
`)

export const MATCH_BANK_TRANSACTION_SLIP = gql(`
    mutation MatchBankTransaction(
      $input: MatchBankTransactionInput!
    ) {
      matchBankTransaction(
        input: $input
      ) {
        bankTransaction {
      ...bankTransaction
    }
    paymentHistory {
      status
      statusUpdatedAt
      _id
      paidAmount
      bankTransactionId
      bankTxRemainingAmount
    }
      }
    }
`)

export const REMOVE_BANK_TRANSACTION = gql(`
  mutation RemoveBankTransaction(
    $id: String!
  ) {
    removeBankTransaction(
      id: $id
    ) {
      ...bankTransaction
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Mutation
 */

export const CHECK_ACCOUNTING_ORDER_STATUS = gql(`
  mutation CheckAccountingOrderStatus($sourceOrderIds: [String!]) {
    checkAccountingOrderStatus(sourceOrderIds: $sourceOrderIds)
  }
`)

export const SYNC_SHOPEE_ACCOUNTING_ORDERS = gql(`
  mutation SyncShopeeAccountingOrders($from: Date!, $to: Date!) {
    syncShopeeAccountingOrders(from: $from, to: $to)
  }
`)

export const SYNC_LAZADA_ACCOUNTING_ORDERS = gql(`
  mutation SyncLazadaAccountingOrders($from: Date!, $to: Date!) {
    syncLazadaAccountingOrders(from: $from, to: $to)
  }
`)

export const SYNC_TIKTOK_ACCOUNTING_ORDERS = gql(`
  mutation SyncTiktokAccountingOrders($from: Date!, $to: Date!) {
    syncTiktokAccountingOrders(from: $from, to: $to)
  }
`)

export const SYNC_MANUAL_ACCOUNTING_ORDERS = gql(`
  mutation SyncManualAccountingOrders($from: Date!, $to: Date!) {
    syncManualAccountingOrders(from: $from, to: $to)
  }
`)

export const SYNC_INVOICE_NUMBERS = gql(`
  mutation SyncInvoiceNumber($from: Date!, $to: Date!) {
    syncInvoiceNumber(from: $from, to: $to)
  }
`)

export const BATCH_SYNC_SHOPEE_ORDERS = gql(`
  mutation BatchSyncShopeeOrders($from: Date!, $to: Date!) {
    batchSyncShopeeOrders(from: $from, to: $to)
  }
`)

export const BATCH_SYNC_LAZADA_ORDERS = gql(`
  mutation BatchSyncLazadaOrders($from: Date!, $to: Date!) {
    batchSyncLazadaOrders(from: $from, to: $to)
  }
`)

/**
 * Query
 */
export const GET_ACCOUNTING_ORDERS_ADVANCED_SEARCH_WITH_META = gql(`
  query GetAccountingOrdersAdvancedSearchWithMeta(
    $advancedSearchFilter: AccountingOrderAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    accountingOrdersAdvancedSearchWithMeta(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      data {
        ...accountingOrder
      }
      meta {
        all
        paid
        unpaid
        pending
        cancelled
      }
    }
  }
`)

export const GET_ACCOUNTING_ORDERS_ADVANCED_SEARCH_META = gql(`
  query GetAccountingOrdersAdvancedSearchMeta($advancedSearchFilter: AccountingOrderAdvancedSearchFilterInput!) {
    accountingOrdersAdvancedSearchMeta(advancedSearchFilter: $advancedSearchFilter) {
      all
      paid
      unpaid
      pending
      cancelled
    }
  }
`)

export const GET_ACCOUNTING_ORDERS_STATISTICS = gql(`
  query GetAccountingOrdersStatistics($filter: AccountingOrderAdvancedSearchFilterInput!) {
    accountingOrderStatistic(filter: $filter) {
      ...accountingOrderStatistic    
    }
  }
`)

export const GET_ACCOUNTING_ORDERS_STATISTIC_BY_DATE = gql(`
  query GetAccountingOrdersStatisticByDate($filter: AccountingOrderAdvancedSearchFilterInput!, $sort: Float) {
    accountingOrderStatisticByDate(filter: $filter, sort: $sort) {
      date
      value {
        ...accountingOrderStatistic
      }
    }
  }
`)

export const GET_STRINGIFY_ACCOUNTING_ORDERS = gql(`
  query GetStringifyAccountingOrders($filter: AccountingOrderAdvancedSearchFilterInput!) {
    getStringifyAccountingOrders(filter: $filter)
  }
`)

export const UPDATE_ACCOUNTING_ORDER_NOTE = gql(`
  mutation UpdateAccountingOrderNote($input: UpdateAccountingOrderNoteInput!) {
    updateAccountingOrderNote(input: $input) {
      ...newNoteResponse
    }
  }
`)

export const SYNC_OLD_ACCOUNTING_ORDER = gql(`
  mutation SyncOldAccountingOrder($marketplaceSource: String!, $from: Date!, $to: Date!) {
    syncOldAccountingOrders(marketplaceSource: $marketplaceSource, from:$from, to:$to)
  }
`)

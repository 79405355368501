import { gql } from 'graphql-generator/__generated__/gql'

export const GET_TAGS = gql(`
  query GetTags($filter: TagFilterInput, $sort: SortInput, $paginationLimit: Float, $paginationOffset: Float) {
    tags(filter: $filter, sort: $sort, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset) {
      _id
      name
    }
  }
`)

export const CREATE_TAG = gql(`
  mutation CreateTag($input: TagInput!) {
    createTag(input: $input) {
      _id
      name
    }
  }
`)

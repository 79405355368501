import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Subscription
 */

export const LEAN_ORDER_SUBSCRIPTION = gql(`
  subscription LeanOrderSubscription(
    $operationTypes: [String!]
    $options: SubscriptionOptions
    $filter: OrderFilterInput
    $advancedSearchFilter: OrderAdvancedSearchFilterInput
  ) {
    leanOrderSubscription(
      operationTypes: $operationTypes
      options: $options
      filter: $filter
      advancedSearchFilter: $advancedSearchFilter
    ) {
      operation
      orderId
      order {
        ...leanOrder
      }
      updatedFields
    }
  }
`)

/**
 * Query
 */

export const GET_LEAN_ORDERS_ADVANCED_SEARCH = gql(`
  query GetLeanOrdersAdvancedSearch(
    $advancedSearchFilter: OrderAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    leanOrdersAdvancedSearch(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      ...leanOrder
    }
  }
`)

export const GET_LEAN_ORDERS_ADVANCED_SEARCH_WITH_META = gql(`
  query GetLeanOrdersAdvancedSearchWithMeta(
    $advancedSearchFilter: OrderAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    leanOrdersAdvancedSearchWithMeta(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      data {
        ...leanOrder
      }
      meta {
        all
        wait_for_production
        prepick
        picked
        printed
        packed
        boarded
        doubted
        cancelled
        transported
        delivered
        wait_return
        returned
        draft
        preorder
        ready_to_ship
        paid
        paid_no_address
      }
    }
  }
`)

export const GET_ORDERS_SEARCH_OPTIMIZED = gql(`
  query GetOrdersSearchOptimized(
    $params: FindLeanOrdersSearchOptimizedInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    findOrdersSearchOptimized(
      params: $params
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      ...optimizedOrderSearchResponse
    }
  }
`)

export const GET_ORDER_META_OPTIMIZED = gql(`
  query GetOrderMetaOptimized(
    $params: FindLeanOrdersSearchOptimizedInput
  ) {
    findOrderMetaOptimized(
      params: $params
    ) {
        all
        wait_for_production
        prepick
        picked
        printed
        packed
        boarded
        doubted
        cancelled
        transported
        delivered
        wait_return
        returned
        draft
        preorder
        ready_to_ship
        paid
        paid_no_address
    }
  }
`)

export const GET_LEAN_ORDERS_ADVANCED_SEARCH_WITH_INVENTORIES = gql(`
  query GetLeanOrdersAdvancedSearchWithInventories(
    $advancedSearchFilter: OrderAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    leanOrdersAdvancedSearchWithInventories(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      ...leanOrder
    }
  }
`)

export const GET_ORDERS_ADVANCED_SEARCH_META = gql(`
  query GetOrdersAdvancedSearchMeta($advancedSearchFilter: OrderAdvancedSearchFilterInput!) {
    ordersAdvancedSearchMeta(advancedSearchFilter: $advancedSearchFilter) {
      all
      wait_for_production
      prepick
      picked
      printed
      packed
      boarded
      doubted
      cancelled
      transported
      delivered
      wait_return
      returned
      draft
      preorder
      ready_to_ship
    }
  }
`)

export const GET_ORDERS = gql(`
  query GetOrders($filter: OrderFilterInput, $paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
    orders(filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort) {
      ...order
    }
  }
`)

export const GET_LEAN_ORDERS = gql(`
  query GetLeanOrders($filter: OrderFilterInput, $paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
    leanOrders(filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort) {
      ...leanOrder
    }
  }
`)

export const GET_PURE_ORDERS = gql(`
  query GetPureOrders($filter: OrderFilterInput, $paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
    pureOrders(filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort) {
      _id
      sourceOrderId
      createdAt
      sourceCreatedTime
       packages {
        _id
        items {
          productName
          productSku
          productImage
          quantity
        }
        status
        sourceStatus
        trackingNumber
      }
    }
  }
`)

export const GET_LEAN_ORDERS_BOOTH_EVENT = gql(`
  query GetLeanOrdersBoothEvent($filter: OrderFilterInput, $paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
    leanOrdersBoothEvent(filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort) {
      ...leanOrder
      staff {
        _id
        staffId
        displayName
      }
      boothLocation {
        _id
        key
        name
      }
    }
  }
`)

export const ORDER_SCAN_PACKED = gql(`
  mutation OrderScanPackedCheck($filter: OrderScanPackedInput!) {
    orderScanPackedCheck(filter: $filter) {
      sourceOrderId
      status
      source
  }
}
`)

export const ORDER_SCAN_ONE_N = gql(`
  mutation OrderScanOneN($input: OrderScanOneNInput!) {
    orderScanOneN(input: $input) {
      order{
        ...order
      }
    }
  }
`)

export const GET_LEAN_ORDERS_WITH_STATUS_META = gql(`
  query GetLeanOrdersWithStatusMeta(
    $filter: OrderFilterInput
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
  ) {
    leanOrdersWithStatusMeta(
      filter: $filter
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
    ) {
      ...leanOrder
    }
  }
`)
export const GET_LEAN_ORDERS_WITH_STATUS_META_ONLY_CURRENT_USER = gql(`
  query GetLeanOrdersWithStatusMetaOnlyCurrentUser(
    $filter: OrderFilterInput
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
  ) {
    leanOrdersWithStatusMetaOnlyCurrenUser(
      filter: $filter
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
    ) {
      ...leanOrder
    }
  }
`)

export const GET_ORDERS_META = gql(`
  query GetOrdersMeta($filter: OrderFilterInput!) {
    ordersMeta(filter: $filter) {
      all
      open
      ready_to_ship
      wait_for_production
      wait_for_approval
      all_approvals_approved_rejected
      prepick
      picked
      printed
      packed
      boarded
      transported
      delivered
      wait_return
      wait_return_cancelled
      returned
      returned_by_delivery_firm
      cancelled
      doubted
      draft
      pending
      lost_and_request_compensation
      lost_and_compensated
      lost_but_rejected
      rejected_approval
      wait_return_more_than_7_days
      paid
      paid_no_address
    }
  }
`)

export const GET_PUBLIC_ORDER = gql(`
  query GetPublicOrder($filter: OrderFilterInput!) {
    publicOrder(filter: $filter) {
      ...leanOrderPublic
    }
  }
`)

export const GET_ORDER = gql(`
  query GetOrder($filter: OrderFilterInput!) {
    order(filter: $filter) {
      ...order
    }
  }
`)

export const GET_MARKETPLACE_ORDER = gql(`
  query GetMarketplaceOrder($filter: OrderMarketplaceInput!) {
    orderMarketplace(filter: $filter) {
      sourceOrderId
      source
      marketplaceOrder
    }
  }
`)

export const TOTAL_EXPENSES = gql(`
  query GetTotalExpenses($advancedSearchFilter: OrderAdvancedSearchFilterInput!) {
    getTotalExpenses(advancedSearchFilter: $advancedSearchFilter) {
      expenses {
        type
        actual
        estimated
      }
      total {
        amount
        count
      }
    }
  }
`)
export const GET_STRINGIFY_ORDERS_FOR_ACCOUNTING = gql(`
  query GetStringifyOrdersForAccounting($advancedSearchFilter: OrderAdvancedSearchFilterInput!, $sort: SortInput) {
    getStringifyOrdersForAccounting(advancedSearchFilter: $advancedSearchFilter, sort: $sort) {
      data
    }
  }
`)

export const COPY_EXCEL_SHEET_ORDERS = gql(`
  query CopyExcelSheetOrders(
    $advancedSearchFilter: OrderAdvancedSearchFilterInput
    $columns: [ExcelOrderColumnInput!]
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    copyExcelSheetOrders(
      advancedSearchFilter: $advancedSearchFilter
      columns: $columns
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    )
  }
`)

export const COUNT_ORDER_DOCUMENTS = gql(`
  query CountOrderDocuments($advancedSearchFilter: OrderAdvancedSearchFilterInput) {
    countOrderDocuments(advancedSearchFilter: $advancedSearchFilter)
  }
`)

export const COUNT_ORDER_BY_ZIPCODE = gql(`
  query CountOrderByZipCode($advancedSearchFilter: OrderAdvancedSearchFilterInput) {
    countByZipCode(advancedSearchFilter: $advancedSearchFilter) {
      zipCodeMeta
    }
  }
`)

export const GET_LEAN_ORDERS_FOR_CS_ADVANCED_SEARCH_WITH_META = gql(`
  query GetLeanOrdersForCsAdvancedSearchWithMeta(
    $advancedSearchFilter: OrderAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
    $projection: OrderProjectionInput
  ) {
    leanOrdersForCsAdvancedSearchWithMeta(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      projection: $projection
    ) {
      data {
        ...leanOrder
      }
      meta {
        all
        paid
        paid_no_address
        no_address
        no_fleet_date
      }
    }
  }
`)

export const GET_ORDERS_FOR_CS_META = gql(`
  query GetOrdersForCsMeta($filter: OrderAdvancedSearchFilterInput!) {
    ordersForCsMeta(filter: $filter) {
      all
      paid
      paid_no_address
      no_address
      no_fleet_date
      produced_but_partial_payment
      paid_and_printed
    }
  }
`)

/**
 * Mutation
 */

export const CREATE_ORDER = gql(`
  mutation CreateOrder($input: OrderInput!) {
    createOrder(input: $input) {
      sourceOrderId
      sourceCreatedTime
      status
      itemMeta {
        finalAmount
      }
      createdBy {
        displayName
      }
      deliveryChannel {
        name
        type
      }
      deliveryOption {
        map
      }
    }
  }
`)

export const CREATE_SIMPLE_ORDER = gql(`
  mutation CreateSimpleOrder($input: PartialOrderDetailsInput!) {
    createSimpleOrder(input: $input) {
      sourceOrderId
      sourceCreatedTime
      status
      itemMeta {
        finalAmount
      }
      createdBy {
        displayName
      }
    }
  }
`)

export const UPDATE_ORDER = gql(`
  mutation UpdateOrder($input: OrderInput!) {
    updateOrder(input: $input) {
      sourceOrderId
      sourceCreatedTime
      status
      itemMeta {
        finalAmount
      }
      createdBy {
        displayName
      }
    }
  }
`)

export const UPDATE_ORDER_DETAILS = gql(`
  mutation UpdateOrderDetails($input: OrderDetailsInput!, $id: String!, $fleetId: String) {
    updateOrderDetails(input: $input, id: $id, fleetId: $fleetId) {
      ...order
    }
  }
`)

export const UPDATE_ORDER_SOURCE_CREATED_TIME = gql(`
  mutation UpdateOrderSourceCreatedTime($input: UpdateOrdersSourceCreatedTimeInput!, $id: String!) {
    updateOrderSourceCreatedTime(input: $input, id: $id) {
      ...order
    }
  }
`)

export const UPDATE_ORDER_ITEMS = gql(`
  mutation UpdateOrderItems($input: OrderItemDetailsInput!, $id: String!) {
    updateOrderItems(input: $input, id: $id) {
      ...order
    }
  }
`)
export const UPDATE_ORDER_PACKAGES = gql(`
  mutation UpdateOrderPackages($input: [OrderPackageInput!]!, $id: String!) {
    updateOrderPackages(input: $input, id: $id) {
      ...order
    }
  }
`)

export const ADD_ORDER_EXPENSE = gql(`
  mutation AddOrderExpense($input: OrderExpenseInput!, $id: String!) {
    addOrderExpense(input: $input, id: $id) {
      ...order
    }
  }
`)

export const REMOVE_ORDER_EXPENSE = gql(`
  mutation RemoveOrderExpense($expenseId: String!, $orderId: String!) {
    removeOrderExpense(expenseId: $expenseId, orderId: $orderId) {
      ...order
    }
  }
`)

export const UPDATE_ORDER_PAYMENT = gql(`
  mutation UpdateOrderPayment($paymentDetails: OrderPaymentDetailsInput!, $id: String!) {
    updateOrderPayment(paymentDetails: $paymentDetails, id: $id) {
      ...order
    }
  }
`)

export const DELETE_ORDER = gql(`
  mutation DeleteOrder($id: String!) {
    deleteOrder(id: $id) {
      ...order
    }
  }
`)

export const UPDATE_ORDER_STATUS = gql(`
  mutation UpdateOrderStatus($input: UpdateOrderStatusInput!) {
    updateOrderStatus(input: $input) {
      ...order
    }
  }
`)

export const UPDATE_ORDERS_STATUS = gql(`
  mutation UpdateOrdersStatus($input: UpdateOrdersStatusInput!) {
    updateOrdersStatus(input: $input) {
      ...order
    }
  }
`)

export const UPDATE_ORDER_PRODUCTION_NOTE = gql(`
  mutation UpdateOrderProductionNote($sourceOrderId: String!, $productionNote: String!) {
    updateOrderProductionNote(sourceOrderId: $sourceOrderId, productionNote: $productionNote) 
  }
`)

export const RETURN_ORDER = gql(`
  mutation ReturnOrder($orderId: String!, $returnedItems: OrderReturnInput!) {
    returnOrder(orderId: $orderId, returnedItems: $returnedItems) {
      ...order
    }
  }
`)
export const GET_ORDER_SHIPPING_LABEL = gql(`
  mutation GetOrderShippingLabel($sourceOrderId: String!) {
    getOrderShippingLabel(sourceOrderId: $sourceOrderId) {
      ...order
    }
  }
`)

export const VALIDATE_ORDERS = gql(`
  mutation ValidateOrders($input: ValidateOrdersInput!) {
    validateOrders(input: $input) {
      orderId
      source
      timestamp
      fromSourceStatus
      toSourceStatus
      fromOrderStatus
      toOrderStatus
    }
  }
`)
export const VALIDATE_ORDERS_BY_ID = gql(`
  mutation ValidateOrdersById($input: ValidateOrdersByIdInput!) {
    validateOrdersById(input: $input) {
      source
      orderId
      fromOrderStatus
      fromSourceStatus
      toOrderStatus
      toSourceStatus
    }
  }
`)
export const VALIDATE_ORDERS_NO_SHIPPING_LABEL = gql(`
  mutation ValidateOrdersNoShippingLabel {
    validateOrdersNoShippingLabel 
  }
`)
export const VALIDATE_ORDERS_LAZADA_SET_READY_TO_SHIP = gql(`
  mutation ValidateOrdersLazadaSetReadyToShip {
    validateOrdersLazadaSetReadyToShip {
      source
      orderId
      fromOrderStatus
      fromSourceStatus
      toOrderStatus
      toSourceStatus
    }
  }
`)

export const VALIDATE_ORDERS_WITH_KERRY = gql(`
  mutation ValidateOrdersWithKerry($sourceOrderIds: [String!]!) {
    validateKerryOrders(sourceOrderIds: $sourceOrderIds) {
      sourceOrderId
    }
  }
`)
export const SCAN_TO_UPDATE_STATUS = gql(`
  mutation ScanToUpdateOrderStatus($input: ScanToUpdateOrderStatusInput!) {
    scanToUpdateOrderStatus(input: $input) {
      identifier
      result
      message
      scannedTotal
      packageTotal
    }
  }
`)
export const UPDATE_TRACKING_NUMBER_PACKAGES = gql(`
  mutation UpdateTrackingNumberPackages($input: [TrackingNumberPackageInput!]!) {
    updateTrackingNumberPackages(input: $input) {
      ...order
    }
  }
`)

export const SCAN_UPDATE_SINGLE_ORDER_STATUS = gql(`
  mutation ScanUpdateSingleOrderStatus($input: ScanUpdateSingleOrderStatusInput!) {
    scanUpdateSingleOrderStatus(input: $input) {
      identifier
      result
      message
      scannedTotal
      packageTotal
    }
  }
`)

export const CREATE_ORDER_EVIDENCE = gql(`
  mutation CreateOrderEvidence($input: OrderEvidenceInput!) {
    createOrderEvidence(input: $input) {
      orderEvidenceId
      driverName
      licensePlate
      deliveryChannelId
      imageEvidence
      note
      workspaceId
      _id
      createdAt
      updatedAt
    }
  }
`)

export const GET_ORDER_ITEM_PICKING_LIST = gql(`
  query GetOrderItemPickingList($filter: OrderFilterInput!) {
    getOrderItemPickingList(filter: $filter) {
      all {
        ...pickingItem
      }
      single {
        ...pickingItem
      }
      multiple {
        ...pickingItem
      }
    }
  }
`)

export const REQUEST_TRACKING_NUMBER_KERRY = gql(`
  mutation RequestNewTrackingNumberKerry($orderIds: [String!], $mode: String) {
    requestNewTrackingKerry(orderIds: $orderIds, mode: $mode)
  }
`)

export const REQUEST_TRACKING_NUMBER_THAI_PARCEL = gql(`
  mutation RequestNewTrackingNumberThaiParcel($orderIds: [String!], $mode: String) {
    requestNewTrackingThaiParcel(orderIds: $orderIds, mode: $mode)
  }
`)

export const UPDATE_PLAIN_ORDERS = gql(`
  mutation UpdatePlainOrders($params: FindManyOrderInput!, $payload: OrderPlainInput!) {
    updatePlainOrders(params: $params, payload: $payload) {
      sourceOrderId
    }
  }
`)

export const CONVERT_DUMMY_TO_MAIN_PRODUCTS = gql(`
  mutation ConvertDummyToMainRatioProducts($input: ConvertProductToMainRatioProductsInput!) {
    convertDummyToMainRatioProducts(input: $input) {
      ...order
    }
  }
`)

export const CALCULATE_PALNNED_EXPENSE = gql(`
  mutation CalculatePlannedExpense($sourceOrderIds: [String!]) {
    calculatePlannedOrderExpense(sourceOrderIds: $sourceOrderIds)
  }
`)

export const GET_ORDER_SLIP = gql(`
  query GetOrderSlips($filter: OrderFilterInput, $paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
    orderSlips(filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort) {
      ...orderSlip
    }
  }
`)

export const GET_ORDER_SLIP_META = gql(`
  query GetOrderSlipsMeta($filter: OrderFilterInput) {
    orderSlipsMeta(filter: $filter) {
      ...orderSlipMeta
    }
  }
`)

export const GET_LEAN_COD_ORDERS_ADVANCED_SEARCH_WITH_META = gql(`
  query GetLeanCODOrdersAdvancedSearchWithMeta(
    $advancedSearchFilter: OrderAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    leanCODOrdersAdvancedSearchWithMeta(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      data {
        ...leanOrder
      }
      meta {
        all
        wait_for_production
        prepick
        picked
        printed
        packed
        boarded
        doubted
        cancelled
        transported
        delivered
        wait_return
        returned
        draft
        preorder
        ready_to_ship
        verified
        partial_verified
        not_verified
      }
    }
  }
`)

export const UPDATE_ORDER_PAYMENT_VERIFY_STATUS = gql(`
    mutation UpdateOrderPaymentVerifyStatus($inputs: [UpdatePaymentVerifyStatusInput!]!) {
      updateOrderPaymentVerifyStatus(inputs: $inputs) {
      ...leanOrder
    }
  }
`)

export const GET_LEAN_COD_ORDER_PACKAGES_ADVANCED_SEARCH_WITH_META = gql(`
  query GetLeanCODOrderPackagesAdvancedSearchWithMeta(
    $advancedSearchFilter: OrderAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    leanCODOrderPackagesAdvancedSearchWithMeta(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      data {
        ...leanOrderSinglePackage
      }
      meta {
       ...verifyStatusMeta
      }
    }
  }
`)

export const GET_COD_ORDER_PACKAGES_ADVANCED_SEARCH_META = gql(`
  query GetCODOrderPackagesAdvancedSearchMeta(
    $param: OrderAdvancedSearchFilterInput!

  ) {
    leanCODOrderPackagesAdvancedSearchMeta(
      param: $param
    ) {
       ...verifyStatusMeta
    }
  }
`)

export const UPDATE_ORDER_PACKAGES_VERIFY_STATUS = gql(`
    mutation UpdateOrderPackagesVerifyStatus($inputs: [UpdatePackageVerifyStatusInput!]!) {
      updateOrderPackageVerifyStatus(inputs: $inputs) {
      ...leanOrder
    }
  }
`)

export const UPDATE_DELIVERY_OPTION = gql(`
  mutation UpdateDeliveryOption($sourceOrderId: String!, $input: DeliveryOptionUpdateInput!) {
    updateDeliveryOption(sourceOrderId: $sourceOrderId, input: $input) {
      ...leanOrder
    }
  }
`)

export const GET_LEAN_ORDERS_ADVANCED_SEARCH_FLEET = gql(`
  query GetLeanOrdersAdvancedSearchFleet(
    $advancedSearchFilter: OrderAdvancedSearchFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    leanOrdersAdvancedSearchFleet(
      advancedSearchFilter: $advancedSearchFilter
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      ...leanOrderFleet
    }
  }
`)

export const COPY_ORDERS_INFO = gql(`
  mutation CopyOrdersInfo(
    $advancedSearchFilter: OrderAdvancedSearchFilterInput
    $columns: [String!]!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    copyOrdersInfo(
      advancedSearchFilter: $advancedSearchFilter
      columns: $columns
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      header
      body
    }
  }
`)

export const GENERATE_ORDER_PAYMENT_QR_CODE_TO_ORDER = gql(`
  mutation GenerateOrderPaymentQRCodeToOrder($input: GenerateOrderQRCodeToOrderInput!) {
    generateOrderQRCodeToOrder(input: $input) {
      ...generateQRCodeToOrderResponse
    }
  }
`)

export const GENERATE_ORDER_PAYMENT_QR_CODE = gql(`
  mutation GenerateOrderPaymentQRCode($input: GenerateOrderQRCodeInput!) {
    generateOrderQRCode(input: $input) {
      ...generateQRCodeResponse
    }
  }
`)

export const SYNC_ORDER_QR_CODE_STATUS = gql(`
  query SyncOrderQRCodeStatus($input: SyncOrderQRCodeStatusInput!) {
    syncOrderQRCodeStatus(input: $input) {
      ...generateQRCodeToOrderResponse
    }
  }
`)

export const CANCEL_ORDER_QR_CODE = gql(`
  mutation CancelOrderQRCode($input: CancelOrderQRCodeInput!) {
    cancelOrderQRCode(input: $input) {
      ...generateQRCodeToOrderResponse
    }
  }
`)

export const VOID_ORDER_QR_CODE = gql(`
  mutation VoidOrderQRCode($input: VoidOrderQRCodeInput!) {
    voidOrderQRCode(input: $input) {
      ...generateQRCodeToOrderResponse
    }
  }
`)

export const GET_ORDER_ID_TOKEN = gql(`
   query GetOrderIdToken($orderId: String!) {
    getOrderIdToken(orderId: $orderId) 
  }
`)

//public api
export const VERIFY_ORDER_ID_TOKEN = gql(`
  query VerifyOrderIdToken($token: String!) {
   verifyOrderIdToken(token: $token) {
    orderId
    exp
    aud
    iat
    iss
    jti
    nbf
    sub
   }
 }
`)

export const UPDATE_ORDER_RECIPIENT_ADDRESS_PUBLIC = gql(`
  mutation UpdateOrderRecipientAddressPublic($token: String!, $input: RecipientAddress!) {
   updateOrderRecipientAddressPublic(token: $token, input: $input) {
    orderId
   }
  }
`)

export const GET_PUBLIC_ORDER_FOR_GOOGLE_MAP = gql(`
 query PublicOrderForGoogleMap($token: String!) {
  publicOrderForGoogleMap(token: $token) {
    orderId
    customerNote
    recipientAddress {
      address
      name
      backupPhoneNumbers
      branch
      country
      district
      email
      mapUrl
      phone
      province
      subDistrict
      zipcode
      googleMapDetail {
          address
          placeName
          placeId
          businessId
          shortUrl
          latLng {
            lat
            lng
          }
        }
    }
  }
}
`)

export const UPDATE_ORDER_DELIVERY_FLEET = gql(`
  mutation UpdateOrderDeliveryFleet($input: UpdateOrderDeliveryFleetInput!) {
    updateOrderDeliveryFleet(input: $input) {
      _id
      fleetId
      deliveryDate
    }
  }
`)

export const ADD_MARKETPLACE_ORDERS_IN_FLEET = gql(`
  mutation AddMarketplaceOrdersInFleet {
    addMarketplaceOrdersInFleet
  }
`)

// export const SEND_ORDER_RECIPIENT_ADDRESS = gql(`
//   mutation SaveLocation($token: String!, $locationData: RecipientAddress!) {
//    saveLocation(token: $token, locationData: $locationData)
//   }
// `)

export const ORDER_SHIPPING_ADDRESS_UPDATED_SUBSCRIPTION = gql(`
  subscription LocationUpdated($orderId: String!, $sessionId: String!) {
    locationUpdated(orderId: $orderId, sessionId: $sessionId) {
      shippingAddress {
        address
        name
        backupPhoneNumbers
        branch
        country
        district
        email
        mapUrl
        phone
        province
        subDistrict
        zipcode
        googleMapDetail {
          address
          placeName
          placeId
          businessId
          shortUrl
          latLng {
            lat
            lng
          }
        }
      }
      status
    }
  }
`)

export const GET_LEAN_ORDER = gql(`
  query GetLeanOrder($filter: OrderFilterInput) {
    leanOrder(filter: $filter) {
      ...leanOrder
    }
  }
`)

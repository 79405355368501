import { gql } from 'graphql-generator/__generated__/gql'

/* -------------------------------------------------------------------------- */
/*                                    Query                                   */
/* -------------------------------------------------------------------------- */

export const GET_CURRENCIES = gql(`
  query GetCurrencies(
    $filter: CurrencyFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getCurrenciesList(
      filter: $filter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
      ...currency
    }
   }
`)

/* -------------------------------------------------------------------------- */
/*                                  Mutation                                  */
/* -------------------------------------------------------------------------- */

export const UPSERT_CURRENCY = gql(`
  mutation UpsertCurrency($input: CurrencyInput!) {
    upsertCurrency(input: $input) {
      ...currency
    }
   }
`)

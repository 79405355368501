import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_NP_ORDER = gql(`
  query GetNPOrder($npDocId: String!) {
    getNPOrder(npDocId: $npDocId) {
      _id
      createdAt
      createdBy {
        displayName
      }
      updatedAt
      workspaceId

      npDocId
      status
      adjustmentDate
      typeProduction
      warehouseId
      warehouse {
          _id
          description
          name
      }
      note
      orders {
        orderId
        sourceOrderId
        productId
        productAlias
        productName
        productSku
        quantity
        productImage
        uniqueId
        runningNumber
        status
        additionalData {
            assignProductionWork
            passedQualityInspection
            defectProduct
            assignFrom
            assignTo
            inspectFrom
            inspectTo
        }
      }
      label
      expectedProductionDate
    }
  }
`)

export const GET_MANY_NP_ORDERS = gql(`
  query GetManyNPOrders($filter: NPOrderFilterInput) {
    getManyNPOrders(filter: $filter) {
    _id
    createdAt
    updatedAt
    workspaceId
    createdBy {
      displayName
    }
    npDocId
    status
    adjustmentDate
    typeProduction
    warehouseId
    note
    orders {
      orderId
      sourceOrderId
      productId
      productAlias
      productName
      productSku
      quantity
      productImage
      uniqueId
      runningNumber
      status
      additionalData {
          assignProductionWork
          passedQualityInspection
          defectProduct
      }
    }
  }
}
`)

export const NP_ORDERS = gql(`
query NPOrders($filter: NPOrderFilterInput, $paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
  npOrders(filter: $filter, paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
    _id
    createdAt
    updatedAt
    workspaceId
    createdBy {
      displayName
    }
    npDocId
    status
    adjustmentDate
    typeProduction
    warehouseId
    note
    orders {
      orderId
      sourceOrderId
      productId
      productAlias
      productName
      productSku
      quantity
      productImage
      uniqueId
      runningNumber
      status
      additionalData {
          assignProductionWork
          passedQualityInspection
          defectProduct
      }
    }
    label
    expectedProductionDate
  }
}
`)

export const NP_ORDERS_WITH_POPULATED_PRODUCT = gql(`
query NPOrdersWithPopulatedProduct($filter: NPOrderFilterInput, $paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
  npOrdersWithPopulatedProduct(filter: $filter, paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
    _id
    createdAt
    updatedAt
    workspaceId
    createdBy {
      displayName
    }
    npDocId
    status
    adjustmentDate
    typeProduction
    warehouseId
    note
    orders {
      orderId
      sourceOrderId
      product {
        _id
        name
        sku
        productAlias
        status
        type
        category {
          _id 
          name
        }
      }
      productAlias
      productName
      productSku
      quantity
      productImage
      uniqueId
      runningNumber
      status
      additionalData {
          assignProductionWork
          passedQualityInspection
          defectProduct
      }
    }
    label
    expectedProductionDate
  }
}
`)

export const NP_ORDERS_STATUS_META = gql(`
  query NPOrdersStatusMeta($filter: NPOrderFilterInput) {
    npOrdersStatusMeta(filter: $filter) {
      pending
      prepared
      in_progress
      inspected
      processed
      cancelled
    }
  }
`)

/**
 *  Mutation
 */
export const CREATE_NP_ORDER = gql(`
mutation CreateNPOrder($input: CreateNPOrderInput!) {
  createNPOrder(input: $input) {
    _id
    createdAt
    updatedAt
    workspaceId
    createdById
    npDocId
    status
    adjustmentDate
    typeProduction
    warehouseId
    note
    orders {
      orderId
      sourceOrderId
      productId
      productAlias
      productName
      productSku
      quantity
      productImage
      uniqueId
      runningNumber
      status
      additionalData {
          assignProductionWork
          passedQualityInspection
          defectProduct
      }
    }
  }
}
`)

export const REVERT_NP_DOC_TO_PRODUCTION_ORDER = gql(`
mutation RevertNPDocToProductionOrder($npDocId: String!, $note: String!) {
  revertNPDocToProductionOrder(npDocId: $npDocId, note: $note) {
    _id
    createdAt
    updatedAt
    workspaceId
    createdById
    npDocId
    status
    adjustmentDate
    typeProduction
    warehouseId
    note
    orders {
      orderId
      sourceOrderId
      productId
      productAlias
      productName
      productSku
      quantity
      productImage
      uniqueId
      runningNumber
      status
      additionalData {
          assignProductionWork
          passedQualityInspection
          defectProduct
      }
    }
  }
}
`)

export const REVERT_NP_DOC_ORDERS_TO_PRODUCTION_ORDER = gql(`
mutation RevertNPDocOrdersToProductionOrder($npDocId: String!, $sourceOrderIds: [String!]!, $note: String) {
  revertNPDocOrdersToProductionOrder(npDocId: $npDocId, sourceOrderIds: $sourceOrderIds, note: $note) {
    _id
    createdAt
    updatedAt
    workspaceId
    createdById
    npDocId
    status
    adjustmentDate
    typeProduction
    warehouseId
    note
    orders {
      orderId
      sourceOrderId
      productId
      productAlias
      productName
      productSku
      quantity
      productImage
      uniqueId
      runningNumber
      status
      additionalData {
        assignProductionWork
        passedQualityInspection
        defectProduct
      }
    }
  }
}
`)

export const REVALIDATE_NP_ORDERS = gql(`
  mutation RevalidateNPOrders {
    revalidateNPOrders
  }
`)

export const REVALIDATE_NP_ORDER = gql(`
  mutation RevalidateNPOrder($npDocId: String!) {
    revalidateNPOrder(npDocId: $npDocId) {
      npDocId
      status
    }
  }
`)

export const UPDATE_MANY_NP_ORDERS_STATUS = gql(`
  mutation UpdateManyNPOrdersStatus($npDocIds: [String!]!, $status: String!) {
    updateManyNPOrdersStatus(npDocIds: $npDocIds, status: $status) {
      errorResult {
        npDocId
        message
      }
      successResult {
        _id
        npDocId
        status
      }
    }
  }
`)

export const GET_NP_ORDERS_BY_PRODUCT_TRACKING_UNIQUE_ID = gql(`
  query GetNPOrdersByProductTrackingUniqueId($params: FindNPOrdersByProductTrackingUniqueIdInput!) {
    npOrdersByProductTrackingUniqueId(params: $params) {
      _id
    createdAt
    updatedAt
    workspaceId
    createdBy {
      displayName
    }
    npDocId
    status
    adjustmentDate
    typeProduction
    warehouseId
    note
    orders {
      orderId
      sourceOrderId
      productId
      productAlias
      productName
      productSku
      quantity
      productImage
      uniqueId
      runningNumber
      status
      additionalData {
          assignProductionWork
          passedQualityInspection
          defectProduct
      }
    }
    label
    expectedProductionDate
    productTrackings {
      ...productTracking
    }
    }
  }
`)

export const GET_MANY_NP_ORDERS_FOR_UNIVERSAL_TF = gql(`
  query GetManyNPOrdersForUniversalTF($filter: NPOrderFilterInput) {
    getManyNPOrders(filter: $filter) {
    _id
    createdAt
    updatedAt
    workspaceId
    createdBy {
      displayName
    }
    npDocId
    status
    adjustmentDate
    typeProduction
    warehouseId
    note
    orders {
      orderId
      sourceOrderId
      productId
      productAlias
      productName
      productSku
      quantity
      productImage
      uniqueId
      runningNumber
      status
      additionalData {
          assignProductionWork
          passedQualityInspection
          defectProduct
      }
    }
    productTrackings {
      ...productTracking
    }
  }
}
`)

export const GET_MANY_NP_ORDERS_WITH_PRODUCT_TRACKING = gql(`
  query GetManyNPOrdersWithProductTracking($filter: NPOrderFilterInput) {
    getManyNPOrdersWithProductTrackings(filter: $filter) {
    _id
    createdAt
    updatedAt
    workspaceId
    createdBy {
      displayName
    }
    npDocId
    status
    adjustmentDate
    typeProduction
    warehouseId
    note
    orders {
      orderId
      sourceOrderId
      productId
      productAlias
      productName
      productSku
      quantity
      productImage
      uniqueId
      runningNumber
      status
      additionalData {
          assignProductionWork
          passedQualityInspection
          defectProduct
      }
    }
    productTrackings {
      ...productTracking
    }
  }
}
`)

import { gql } from 'graphql-generator/__generated__/gql'

/* -------------------------------------------------------------------------- */
/*                                    query                                   */
/* -------------------------------------------------------------------------- */
export const GET_TRCLOUD_INTEGRATION_SOURCES = gql(`
  query GetTrcloudIntegrationSources(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: TRCloudSourceFilterInput
  ) {
    trcloudIntegrationSources(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...trcloudIntegration
    }
  }
`)

// export const GET_ORDER_SOURCES_META = gql(`
//   query GetOrderSourcesMeta {
//     orderSourcesMeta {
//       all
//       marketplace
//       manual
//     }
//   }
// `)

// export const CREATE_ORDER_SOURCE = gql(`
//   mutation CreateOrderSource($input: OrderSourceInput!) {
//     createOrderSource(input: $input) {
//       _id
//       name
//     }
//   }
// `)

/* -------------------------------------------------------------------------- */
/*                                  mutation                                  */
/* -------------------------------------------------------------------------- */
export const INSERT_TRCLOUD_INTEGRATION_SOURCE = gql(`
  mutation InsertTRCloudSource($inputs: [TRCloudSourceInput!]!) {
    insertTRCloudSource(inputs: $inputs) {
      _id
      profile {
        name
      }
    }
  }
`)

// export const UPDATE_ORDER_SOURCE = gql(`
//   mutation UpdateOrderSource($id: String!, $input: OrderSourceInput!) {
//     updateOrderSource(id: $id, input: $input) {
//       _id
//       name
//     }
//   }
// `)/

export const UPDATE_STATUS_TRCLOUD_INTEGRATION = gql(`
  mutation UpdateStatusTRCloudIntegration($payload: TRCloudSourcePayloadInput!) {
    updateStatusTRCloudIntegration(payload: $payload) {
      ...trcloudIntegration
    }
  }
`)

export const DELETE_TRCLOUD_INTEGRATION_SOURCE = gql(`
  mutation DeleteTRCloudIntegration($filter: TRCloudSourceFilterInput!) {
    deleteTRCloudIntegration(filter: $filter) {
      _id
      profile {
        name
      }
    }
  }
`)

export const UPSERT_TRCLOUD_FORMULA = gql(`
  mutation UpsertTRCloudFormula($payload: TRCloudFormulaPayloadInput!) {
    upsertTRCloudFormula(payload: $payload) {
      ...trcloudIntegration
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_CATEGORY = gql(`
  query GetCategory(
    $id: String!
  ) {
    category(id: $id) {
      ...category
    }
  }
`)

export const GET_CATEGORY_WITH_ALL_ATTRIBUTES = gql(`
  query CategoryWithAllAttributes(
    $id: String!
  ) {
    categoryWithAllAtributes(id: $id) {
      ...category
    }
  }
`)

export const GET_CATEGORY_WITH_PARENT = gql(`
  query CategoryWithParent(
    $id: String!
  ) {
    categoryWithParent(id: $id) {
      category {
        ...category
      }
      parent {
        category {
          ...category
        }
        parent {
          category {
            ...category
          }
          parent {
            category {
              ...category
            }
            parent {
              category {
                ...category
              }
              # Keep nesting for deeper levels if needed
            }
            siblings {
              ...category
            }
          }
          siblings {
            ...category
          }
        }
        siblings {
          ...category
        }
      }
      siblings {
        ...category
      }
    }
  }
`)

export const GET_CATEGORY_WITH_CHILDREN = gql(`
  query CategoryWithChildren(
    $id: String!
  ) {
    categoryWithChildren(id: $id) {
      category {
        ...category
      }
      children {
        category {
          ...category
        }
        children {
          category {
            ...category
          }
          children {
            category {
              ...category
            }
            children {
              category {
                ...category
              }
              # Keep nesting for deeper levels if needed
            } 
          } 
        } 
      }
    }
  }
`)

export const GET_CATEGORIES = gql(`
  query GetCategories(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: CategoryFilterInput
  ) {
    categories(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort, filter: $filter) {
      ...category
    }

    categoriesMeta {
      total
    }
  }
`)
export const GET_CATEGORIES_WITH_META = gql(`
  query GetCategoriesWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: CategoryFilterInput
  ) {
    categoriesWithMeta(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...category
      meta {
        ...productsMeta
      }
    }

    categoriesMeta {
      total
    }
  }
`)

/**
 * Mutation
 */

export const CREATE_CATEGORY = gql(`
  mutation CreateCategory($payload: CategoryInput!) {
    createCategory(payload: $payload) {
      ...category
    }
  }
`)

export const UPDATE_CATEGORY = gql(`
  mutation UpdateCategory($payload: CategoryInput!, $params: FindOneCategoryInput!) {
    updateCategory(payload: $payload, params: $params) {
      ...category
    }
  }
`)

export const DELETE_CATEGORY = gql(`
  mutation DeleteCategory($_id: String!) {
    deleteCategory(_id: $_id) {
      ...category
    }
  }
`)

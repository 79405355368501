import { gql } from 'graphql-tag'

/**
 * Mutation
 */

export const CREATE_LAZADA_TRANSACTION_FEE = gql(`
  mutation CreateLazadaTransactionFee($payload: LazadaTransactionFeeInput!) {
    createLazadaTransactionFee(payload: $payload) {
      ...lazadaTransactionFee
    }
  }
`)

export const DELETE_LAZADA_TRANSACTION_FEE = gql(`
  mutation DeleteLazadaTransactionFee($_id: String!) {
    deleteLazadaTransactionFee(_id: $_id) {
      ...lazadaTransactionFee
    }
  }
`)

export const UPDATE_LAZADA_TRANSACTION_FEE = gql(`
  mutation UpdateLazadaTransactionFee(
    $payload: LazadaTransactionFeeUpdateInput!
    $params: FindOneLazadaTransactionFeeInput!
  ) {
    updateLazadaTransactionFee(payload: $payload, params: $params) {
      ...lazadaTransactionFee
    }
  }
`)

/**
 * Query
 */

export const GET_LAZADA_TRANSACTION_FEES = gql(`
  query GetLazadaTransactionFees(
    $paginationLimit: Float
    $paginationOffset: Float
    $sort: SortInput
    $filter: LazadaTransactionFeeFilterInput
  ) {
    lazadaTransactionFees(
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
      filter: $filter
    ) {
      ...lazadaTransactionFee
    }
  }
`)

export const GET_LAZADA_TRANSACTION_FEES_WITH_META = gql(`
  query GetLazadaTransactionFeesWithMeta(
    $paginationLimit: Float
    $paginationOffset: Float
    $sort: SortInput
    $filter: LazadaTransactionFeeFilterInput
  ) {
    lazadaTransactionFeesWithMeta(
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
      filter: $filter
    ) {
      ...lazadaTransactionFee
    }

    lazadaTransactionFeesMeta {
      total
    }
  }
`)

export const GET_LAZADA_TRANSACTION_FEE = gql(`
  query GetLazadaTransactionFee($id: String!) {
    lazadaTransactionFee(id: $id) {
      ...lazadaTransactionFee
    }
  }
`)

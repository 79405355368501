import { gql } from 'graphql-generator/__generated__/gql'

export const GET_USERS = gql(`
  query GetUsers {
    getUsers {
      ...user
    }
  }
`)

export const GET_THIS_USER = gql(`
  query GetUser($input: UserFilterInputDto!, $from: String) {
    getUserWithFilter(input: $input, from: $from) {
      ...user
    }
  }
`)

export const GET_USERS_WITH_ADVANCED_SEARCH = gql(`
  query GetUsersWithAdvancedSearchFilter($filter: UserAdvancedSearchFilterInput!) {
    getUsersWithAdvancedSearchFilter(filter: $filter) {
      ...user
    }
  }
`)

export const GET_USERS_WHO_ASSIGNED = gql(`
  query GetUsersWhoAssigned {
    getUsersWhoAssigned {
      ...user
    }
  }
`)

export const GET_SYSTEM_USER = gql(`
  query GetSystemUser {
    getSystemUser {
      ...user
    }
  }
`)

export const SWITCH_ASSIGN_USER = gql(`
  mutation SwitchAssignUser($uid: String!, $assignable: Boolean!) {
    switchAssignUser(uid: $uid, assignable: $assignable) {
      ...user
    }
  }
`)

export const REGISTER_USER = gql(`
  mutation Register($input: FirebaseUserInput!) {
    register(input: $input) {
      ...user
    }
  }
`)

export const REGISTER_WITH_INVITE_LINK = gql(`
  mutation RegisterWithInviteLink($input: FirebaseUserInput!) {
    registerWithInviteLink(input: $input) {
      ...user
    }
  }
`)

export const LOGIN_USER_AFTER_AUTH0 = gql(`
  mutation Login($input: FirebaseUserInput!) {
    login(input: $input) {
      ...userWithWorkspace
    }
  }
`)
export const LOGIN_USER_SESSION = gql(`
  mutation LoginSession($input: FirebaseUserInput!) {
    login(input: $input) {
      ...userWithWorkspace
    }
  }
`)

export const LOGIN_WORKSPACE = gql(`
  mutation LoginWorkspace($workspaceId: String!) {
    loginWorkspace(workspaceId: $workspaceId) {
      workspace {
        ...authWorkspace
      }
      user {
        ...user
      }
      roles {
        workspaceId
        name
        description
        permissions {
          feature
          actions
        }
        _id
      }
      ...workspaceMember
    }
  }
`)

export const LOGOUT_USER = gql(`
  mutation Logout {
    logout
  }
`)

export const LOGIN_DATA_MOAT = gql(`
  mutation LoginDataMoat($loginDataMoatInput: LoginDataMoatInput!) {
    loginDataMoat(loginDataMoatInput: $loginDataMoatInput)
  }
`)

export const COMPUTE_RISK = gql(`
  mutation ComputeRisk($ip: String!) {
    computeRisk(ip: $ip)
  }
`)

export const GET_WORKSPACE = gql(`
  query GetWorkspace {
    workspace {
      ...authWorkspace
    }
  }
`)

export const SEND_INVITE_MAILS = gql(`
  mutation SendInviteMails($inputs: [InviteInput!]!) {
    sendInviteMails(inputs: $inputs) {
      accepted
      rejected
    }
  }
`)

export const CREATE_INVITE_LINK = gql(`
  mutation CreateInviteLink($input: InviteInput!) {
    createInviteLink(input: $input) {
      url
    }
  }
`)

export const GET_REF_CODE_DETAIL = gql(`
  query GetRefCodeDetail($refCode: String!) {
    refCodeDetail(refCode: $refCode) {
      displayName
      recipient
      workspace {
        image
        name
        totalMembers
      }
      role {
        name
      }
      referer {
        photoURL
        displayName
      }
    }
  }
`)
export const ACCEPTED_INVITATION = gql(`
  mutation AcceptedInvitation($refCode: String!) {
    accptedInvitation(refCode: $refCode) {
      ...workspaceMember
    }
  }
`)

/**
 * Third-party authorization
 */

export const GET_LAZADA_AUTH_URL = gql(`
  query GetLazadaAuthUrl($input: LazadaAuthUrlInput!) {
    getLazadaAuthUrl(input: $input)
  }
`)

export const VALIDATE_LAZADA_CODE = gql(`
  mutation ValidateLazadaCode($input: ValidateLazadaCodeInput!) {
    validateLazadaCode(input: $input) {
      shopId
      shopName
      shopLogo
      country
    }
  }
`)

export const GET_SHOPEE_AUTH_URL = gql(`
  query GetShopeeAuthUrl($input: ShopeeAuthUrlInput!) {
    getShopeeAuthUrl(input: $input)
  }
`)

export const VALIDATE_SHOPEE_CODE = gql(`
  mutation ValidateShopeeCode($input: ValidateShopeeCodeInput!) {
    validateShopeeCode(input: $input) {
      shopId
      shopName
      shopLogo
      country
    }
  }
`)

export const GET_TIKTOK_AUTH_URL = gql(`
  query GetTikTokAuthUrl($input: TiktokAuthUrlInput!) {
    getTiktokAuthUrl(input: $input)
  }
`)

export const VALIDATE_TIKTOK_CODE = gql(`
  mutation ValidateTiktokCode($input: ValidateTiktokCodeInput!) {
    validateTiktokCode(input: $input) {
      shopId
      shopName
      shopLogo
      country
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

export const SYNC_MARKETPLACE_SHOPEE_INFO = gql(`
  mutation SyncMarketplaceShopeeInfo {
    syncShopeeInfo
  }
`)
export const SYNC_MARKETPLACE_LAZADA_INFO = gql(`
  mutation SyncMarketplaceLazadaInfo {
    syncLazadaInfo
  }
`)
export const SYNC_MARKETPLACE_TIKTOK_INFO = gql(`
  mutation SyncMarketplaceTiktokInfo {
    syncTiktokInfo
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

export const GET_MANY_STOCK_PRODUCT = gql(`
  query GetManyStockProduct($filter: GetManyStockProductFilterInput, $limit: Int, $offset: Int, $sort: SortInput) {
    getManyStockProduct(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
      data {
        _id
        sku
        image
        name
        alias
        categoryId
        categoryName
        quantity
        alertQuantity
        status
        unitValue
        unit
        baseUnit
        pricePerUnit
        type
        workspaceId
        createdById
        createdAt
        updatedAt
      }
      total
    }
  }
`)

export const GET_STOCK_PRODUCT = gql(`
  query GetStockProduct($filter: GetManyStockProductFilterInput) {
    getStockProduct(filter: $filter) {
      data {
        _id
        sku
        image
        name
        alias
        categoryId
        categoryName
        quantity
        alertQuantity
        status
        unitValue
        unit
        baseUnit
        pricePerUnit
        type
        workspaceId
        createdById
        createdAt
        updatedAt
      }
    }
  }
`)

export const CREATE_STOCK_PRODUCT = gql(`
  mutation CreateStockProduct($input: CreateStockProductInput!) {
    createStockProduct(input: $input) {
      _id
      sku
      image
      name
      alias
      categoryId
      categoryName
      quantity
      alertQuantity
      status
      unitValue
      unit
      baseUnit
      pricePerUnit
      type
      workspaceId
      createdById
      createdAt
      updatedAt
    }
  }
`)

export const UPDATE_STOCK_PRODUCT_BY_ID = gql(`
  mutation UpdateStockProductById($_id: String!, $input: UpdateStockProductInput!) {
    updateStockProductById(_id: $_id, input: $input) {
      _id
      sku
      image
      name
      alias
      categoryId
      categoryName
      quantity
      alertQuantity
      status
      unitValue
      unit
      baseUnit
      pricePerUnit
      type
      workspaceId
      createdById
      createdAt
      updatedAt
    }
  }
`)
import { gql } from 'graphql-generator/__generated__/gql'

export const GET_MANY_STOCK_PRODUCT_CATEGORY = gql(`
  query GetManyStockProductCategory($filter: GetManyStockProductCategoryFilterInput, $paginationLimit: Float, $paginationOffset: Float, $sort: SortInput) {
    getManyStockProductCategory(filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort) {
      data { 
        _id
        name
        workspaceId
        createdById
        createdAt
        updatedAt
      }
      total
    }
  }
`)

export const CREATE_STOCK_PRODUCT_CATEGORY = gql(`
  mutation CreateStockProductCategory($input: CreateStockProductCategoryInput!) {
    createStockProductCategory(input: $input) {
      _id
      name
      workspaceId
      createdById
      createdAt
      updatedAt
    }
  }
`)

export const DELETE_STOCK_PRODUCT_CATEGORY_BY_ID = gql(`
  mutation DeleteStockProductCategoryById($_id: String!) {
    deleteStockProductCategoryById(_id: $_id) {
      _id
    }
  }
`)

import { gql } from 'graphql-tag'

/**
 * Mutation
 */

export const CREATE_SHOPEE_PROMOTIONAL_PROGRAM = gql(`
  mutation CreateShopeePromotionalProgram($payload: ShopeePromotionalProgramInput!) {
    createShopeePromotionalProgram(payload: $payload) {
      ...shopeePromotionalProgram
    }
  }
`)

export const DELETE_SHOPEE_PROMOTIONAL_PROGRAM = gql(`
  mutation DeleteShopeePromotionalProgram($_id: String!) {
    deleteShopeePromotionalProgram(_id: $_id) {
      ...shopeePromotionalProgram
    }
  }
`)

export const UPDATE_SHOPEE_PROMOTIONAL_PROGRAM = gql(`
  mutation UpdateShopeePromotionalProgram(
    $payload: ShopeePromotionalProgramUpdateInput!
    $params: FindOneShopeePromotionalProgramInput!
  ) {
    updateShopeePromotionalProgram(payload: $payload, params: $params) {
      ...shopeePromotionalProgram
    }
  }
`)

/**
 * Query
 */

export const GET_SHOPEE_PROMOTIONAL_PROGRAMS = gql(`
  query GetShopeePromotionalPrograms(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: ShopeePromotionalProgramFilterInput
  ) {
    shopeePromotionalPrograms(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...shopeePromotionalProgram
    }
  }
`)

export const GET_SHOPEE_PROMOTIONAL_PROGRAMS_WITH_META = gql(`
  query GetShopeePromotionalProgramsWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: ShopeePromotionalProgramFilterInput
  ) {
    shopeePromotionalProgramsWithMeta(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...shopeePromotionalProgram
    }

    shopeePromotionalProgramMeta {
      total
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_PRODUCTION_LINE = gql(`
  query GetProductionLine($filter: ProductionLineFilter!) {
    getProductionLine(filter: $filter) {
      ...productionLine
    }
  }
`)

export const GET_MANY_PRODUCTION_LINE = gql(`
  query GetManyProductionLine(
    $filter: ProductionLineFilter!
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
  ) {
    getManyProductionLine(
      filter: $filter
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
    ) {
      ...productionLine
    }
  }
`)

export const GET_ORDER_DELIVERY_DATE = gql(`
  query GetOrderDeliveryDate($input: ProductionLineDeliveryDateInput!) {
    getOrderDeliveryDate(input: $input) {
      lineIds
      deliveryDate
    }
  }
`)

export const GET_MANY_PRODUCTION_LINE_ORDER_DOCUMENTS = gql(`
  query GetManyProductionLin_OrderDocuments(
    $filter: ProductionLineFilter!
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
  ) {
    getManyProductionLine_OrderDocuments(
      filter: $filter
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
    ) {
      ...productionLineOrderDocuments
    }
  }
`)

/**
 * Mutation
 */

export const CREATE_PRODUCTION_LINE = gql(`
  mutation CreateProductionLine($input: ProductionLineInput!) {
    createProductionLine(input: $input) {
      ...productionLine
    }
  }
`)

export const UPDATE_PRODUCTION_LINE = gql(`
  mutation UpdateProductionLine($lineId: String!, $input: ProductionLineUpdateInput!) {
    updateProductionLine(lineId: $lineId, input: $input) {
      ...productionLine
    }
  }
`)

export const DELETE_PRODUCTION_LINE = gql(`
  mutation DeleteProductionLine($lineId: String!) {
    deleteProductionLine(lineId: $lineId) {
      ...productionLine
    }
  }
`)

export const RESERVE_ORDER_IN_PRODUCTION_LINE = gql(`
  mutation ReserveOrderInProductionLine($sourceOrderId: String!, $lineIds: [String!]!, $fleetId: String!) {
    reserveOrderInProductionLine(sourceOrderId: $sourceOrderId, lineIds: $lineIds, fleetId: $fleetId) {
      ...productionLine
    }
  }
`)

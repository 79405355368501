import { gql } from 'graphql-generator/__generated__'

export const CREATE_PRODUCTION_PLAN = gql(`
mutation CreateProductionPlan($input: CreateProductionPlanInput!) {
    createProductionPlan(input: $input) {
        productionPlanId
    }
}
`)

export const UPDATE_PRODUCTION_PLAN = gql(`
mutation UpdateProductionPlan($input: UpdateProductionPlanInput!) {
    updateOneProductionPlan(input: $input) {
        productionPlanId
    }
}
`)

export const UPDATE_PRODUCTION_PLAN_STATUS = gql(`
mutation UpdateProductionPlanStatus($input: UpdateProductionPlanStatusInput!) {
    updateProductionPlanStatus(input: $input) {
        productionPlanId
    }
}
`)

export const GET_ONE_PRODUCTION_PLAN = gql(`
query GetOneProductionPlan($input: ProductionPlanFilterInput!) {
    getOneProductionPlan(input: $input) {
        ...productionPlan
    }
}
`)

export const GET_MANY_PRODUCTION_PLANS = gql(`
query GetManyProductionPlans($input: ProductionPlanFilterInput!) {
    getManyProductionPlans(input: $input) {
        ...productionPlan
    }
}
`)

export const GET_PRODUCTION_PLAN_META = gql(`
query GetProductionPlanMeta($input: ProductionPlanFilterInput!) {
    getProductionPlanMeta(input: $input) {
        ...productionPlanMeta
    }
}
`)

export const GET_PRODUCTION_ORDER_BY_PLAN_ID = gql(`
query GetProductionOrderByProductionPlanId($productionPlanId: String!) {
    getProductionOrderByProductionPlanId(productionPlanId: $productionPlanId) {
        ...leanInventoryAdjustmentOrder
    }
}
`)

export const CONVERT_PRODUCTION_PLAN_TO_NEW_STRUCTURE = gql(`
mutation ConvertProductionPlanToNewStructure($productionPlanId: String!) {
  convertToNewPMFStructure(productionPlanId: $productionPlanId)
}
`)

export const GET_MANY_NP_ORDERS_BY_PRODUCTION_PLAN = gql(`
query GetManyNPOrdersByProductionPlan($productionPlanId: String!) {
    getNPOrderByProductionPlanId(productionPlanId: $productionPlanId) {
        _id
        createdAt
        updatedAt
        workspaceId
        createdById
        npDocId
        status
        adjustmentDate
        typeProduction
        warehouseId
        note
        orders {
            orderId
            sourceOrderId
            productId
            productAlias
            productSku
            quantity
            productImage
            uniqueId
            status
        }
    }      
}
`)

import { gql } from 'graphql-generator/__generated__/gql'
// ------------------------------ Fake Shipped --------------------------
export const GET_NEAR_LATE_SHIPPING_ORDERS = gql(`
    query GetNearLateShippingOrders($source: String!, $filter: OrderFilterInput, $paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
        getNearLateShippingOrders(source: $source, filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort) {
        ...nearLateOrder
      }
    }
  `)
export const GET_NEAR_LATE_SHIPPING_ORDER_META = gql(`
    query GetNearLateShippingOrderMeta($filter: OrderFilterInput) {
      getNearLateShippingOrderMeta(filter: $filter) {
        shopee
        tiktok
        shopeeShippedTrue
        tiktokShippedTrue
      }
    }
  `)

export const UPDATE_MANY_FAKE_SHIPPED_ORDERS = gql(`
   mutation UpdateManyFakeShippedOrders($input: UpdateManyFakeShippedOrdersInput!) {
  updateManyFakeShippedOrders(input: $input) {
    ...nearLateOrder
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

export const GENERATE_READ_SIGNED_URL = gql(`
  query GenerateReadSignedUrl($filename: String!) {
    generateReadSignedUrl(filename: $filename) {
      url
    }
  }
`)

export const GENERATE_UPLOAD_SIGNED_URL = gql(`
  query GenerateUploadSignedUrl($filename: String!, $type: String!) {
    generateUploadSignedUrl(filename: $filename, type: $type) {
      url
    }
  }
`)

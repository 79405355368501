import { gql } from 'graphql-tag'


/**
 * Mutation
 */

export const CREATE_SHOPEE_PAYMENT_TRANSACTION = gql(`
  mutation CreateShopeePaymentTransaction($payload: ShopeePaymentTransactionInput!) {
    createShopeePaymentTransaction(payload: $payload) {
      ...shopeePaymentTransaction
    }
  }
`)

export const DELETE_SHOPEE_PAYMENT_TRANSACTION = gql(`
  mutation DeleteShopeePaymentTransaction($_id: String!) {
    deleteShopeePaymentTransaction(_id: $_id) {
      ...shopeePaymentTransaction
    }
  }
`)

export const UPDATE_SHOPEE_PAYMENT_TRANSACTION = gql(`
  mutation UpdateShopeePaymentTransaction(
    $payload: ShopeePaymentTransactionUpdateInput!
    $params: FindOneShopeePaymentTransactionInput!
  ) {
    updateShopeePaymentTransaction(payload: $payload, params: $params) {
      ...shopeePaymentTransaction
    }
  }
`)

/**
 * Query
 */

export const GET_SHOPEE_PAYMENT_TRANSACTIONS = gql(`
  query GetShopeePaymentTransactions(
    $paginationLimit: Float
    $paginationOffset: Float
    $sort: SortInput
    $filter: ShopeePaymentTransactionFilterInput
  ) {
    shopeePaymentTransactions(
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
      filter: $filter
    ) {
      ...shopeePaymentTransaction
    }
  }
`)

export const GET_SHOPEE_PAYMENT_TRANSACTIONS_WITH_META = gql(`
  query GetShopeePaymentTransactionsWithMeta(
    $paginationLimit: Float
    $paginationOffset: Float
    $sort: SortInput
    $filter: ShopeePaymentTransactionFilterInput
  ) {
    shopeePaymentTransactionsWithMeta(
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
      sort: $sort
      filter: $filter
    ) {
      ...shopeePaymentTransaction
    }

    shopeePaymentTransactionMeta {
      total
    }
  }
`)

export const GET_SHOPEE_PAYMENT_TRANSACTION = gql(`
  query GetShopeePaymentTransaction($id: String!) {
    shopeePaymentTransaction(id: $id) {
      _id
    }
  }
`)

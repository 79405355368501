import { gql } from 'graphql-generator/__generated__/gql'

export const CREATE_STOCK_PRODUCT_DOCUMENT = gql(`
  mutation CreateStockProductDocument($input: CreateStockProductDocumentInput!) {
    createStockProductDocument(input: $input) {
      _id
      createdAt
      createdBy {
        _id
        email
      }
      documentNo
      documentType
      note
      status
      poNumber
      forDepartment
      archived
      warehouse {
        _id
        address
        createdAt
        createdById
        name
        updatedAt
        workspaceId
      }
      summaries {
        currentQuantity
        newQuantity
        stockProduct {
          _id
          name
          image
          sku
          alias
          categoryName
          unit
          status
        }
        originSummary {
          stockProduct {
            _id
            name
            image
            sku
            alias
            categoryName
            unit
            status
          }
        }
        destinationSummary {
          stockProduct {
            _id
            name
            image
            sku
            alias
            categoryName
            unit
            status
          }
        }
      }
    }
  }
`)

export const GET_STOCK_PRODUCT_DOCUMENT_BY_ID = gql(`
  query GetStockProductDocumentById($input: GetStockProductDocumentByIdInput!) {
    getStockProductDocumentById(input: $input) {
      _id
      createdAt
      createdBy {
        _id
        email
      }
      documentNo
      documentType
      note
      status
      poNumber
      forDepartment
      archived
      warehouse {
        _id
        address
        createdAt
        createdById
        name
        updatedAt
        workspaceId
      }
      summaries {
        currentQuantity
        newQuantity
        stockProduct {
          _id
          name
          image
          sku
          alias
          categoryName
          unit
          status
        }
        originSummary {
          currentQuantity
          newQuantity
          stockProduct {
            _id
            name
            image
            sku
            alias
            categoryName
            unit
            status
          }
        }
        destinationSummary {
          currentQuantity
          newQuantity
          stockProduct {
            _id
            name
            image
            sku
            alias
            categoryName
            unit
            status
          }
        }
      }
    }
  }
`)

export const GET_MANY_STOCK_PRODUCT_DOCUMENT = gql(`
  query GetManyStockProductDocument($filter: GetManyStockProductDocumentInput) {
    getManyStockProductDocument(filter: $filter) {
      _id
      archived
      createdAt
      documentNo
      documentType
      poNumber
      forDepartment
      note
      status
      warehouseId
    }
  }
`)

export const UPDATE_STOCK_PRODUCT_DOCUMENT_STATUS_BY_ID = gql(`
  mutation UpdateStockProductDocumentStatusByDocumemntId(
    $input: UpdateStockProductDocumentStatusByIdInput!
  ) {
    updateStockProductDocumentStatusByDocumemntId(input: $input) {
      _id
      status
    }
  }
`)
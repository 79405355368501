import { gql } from 'graphql-generator/__generated__/gql'

/* -------------------------------------------------------------------------- */
/*                                   Queries                                  */
/* -------------------------------------------------------------------------- */

export const GET_PRODUCT_ATTRIBUTES_META = gql(`
  query ProductAttributesMeta($filter: ProductAttributeFilterInput) {
    productAttributesMeta(filter: $filter) {
      total
    }
  }
`)

export const GET_PRODUCT_ATTRIBUTE = gql(`
  query GetProductAttribute($id: String!) {
    getProductAttribute(id: $id) {
        ...productAttribute
    }
  }
`)

export const GET_MANY_PRODUCT_ATTRIBUTES = gql(`
  query GetManyProductAttributes($filter: ProductAttributeFilterInput, $paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
    getManyProductAttributes(filter: $filter, paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
        ...productAttribute
    }
  }
`)

export const GET_MANY_PRODUCT_ATTRIBUTES_WITH_PRODUCT_META = gql(`
  query GetManyProductAttributesWithProductMeta($filter: ProductAttributeFilterInput, $paginationOffset: Float, $paginationLimit: Float, $sort: SortInput) {
    getManyProductAttributesWithProductMeta(filter: $filter, paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
        ...productAttribute
    }
  }
`)

/* -------------------------------------------------------------------------- */
/*                                   Mutations                                */
/* -------------------------------------------------------------------------- */

export const CREATE_PRODUCT_ATTRIBUTE_WITH_VALUES = gql(`
  mutation CreateProductAttributeWithValues($payload: CreateProductAttributePayload!) {
    createProductAttributeWithValues(payload: $payload) {
      ...productAttribute
    }
  }
`)

export const UPDATE_PRODUCT_ATTRIBUTE = gql(`
  mutation UpdateProductAttribute($id: String!, $name: String!) {
    updateProductAttribute(id: $id, name: $name) {
      ...productAttribute
    }
  }
`)

export const UPDATE_MANY_PRODUCT_ATTRIBUTES = gql(`
  mutation UpdateManyProductAttributes($input: UpdateManyProductAttributeInput!) {
    updateManyProductAttributes(input: $input) {
      successTotal
      fail {
        detail {
          message
          id
        }
        total
      }
    }
  }
`)

export const DELETE_MANY_PRODUCT_ATTRIBUTES = gql(`
  mutation DeleteManyProductAttributes($input: DeleteManyProductAttributesInput!) {
    deleteManyProductAttributes(input: $input){
      deletedAttributesCount
      deletedValuesCount
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

/* -------------------------------------------------------------------------- */
/*                                    Query                                   */
/* -------------------------------------------------------------------------- */

export const GET_TRCLOUD_LOG_LIST = gql(`
  query GetTRCloudLogs(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $advancedSearchFilter: TRCloudAdvancedSearchFilterInput
  ) {
    getTRCloudLogs(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      advancedSearchFilter: $advancedSearchFilter
    ) {
      data {
        ...trcloudstate
      }
      meta {
        all
      }
    }
  }
`)

export const GET_TRCLOUD_LOG = gql(`
  query GetTrcloudLog($filter: TRCloudFilterInput!) {
    getTRCloudLog(filter: $filter) {
      ...trcloudstate
    }
  }
`)

/* -------------------------------------------------------------------------- */
/*                                  Mutation                                  */
/* -------------------------------------------------------------------------- */

import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Querys
 */
export const GET_TASK = gql(`
  query GetTask($filter: TaskFilterInput!) {
    getTask(filter: $filter) {
      ...task
    }
  }
`)

export const GET_MANY_TASK = gql(`
  query GetManyTask($filter: TaskFilterInput!) {
    getManyTask(filter: $filter) {
      ...task
    }
  }
`)

export const COUNT_TASK = gql(`
  query CountTask($filter: TaskFilterInput!) {
    countTask(filter: $filter)
  }
`)

/**
 * Mutations
 */
export const CREATE_TASK = gql(`
  mutation CreateTask($input: TaskCreateInput!) {
    createTask(input: $input) {
      ...task
    }
  }
`)

export const UPDATE_TASK = gql(`
  mutation UpdateTask($taskId: String!, $input: TaskUpdateInput!) {
    updateTask(taskId: $taskId, input: $input) {
      ...task
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_MANY_PRODUCT_SUBTYPE = gql(`
  query GetManyProductSubType(
    $filter: ProductSubTypeFilterInput
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getManyProductSubType(
      filter: $filter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
      ...productSubType
    }
  }
`)

export const GET_MANY_PRODUCT_SUBTYPE_WITH_META = gql(`
  query GetManyProductSubTypeWithMeta(
    $filter: ProductSubTypeFilterInput
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getManyProductSubTypeWithMeta(
      filter: $filter
      sort: $sort
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
    ) {
        data {
          ...productSubType
        }
        meta {
          all 
        }
    }
  }
`)

export const GET_PRODUCT_SUBTYPE_OPTIONS = gql(`
  query GetProductSubTypeOptions {
    getProductSubTypeOptions {
      value,
      label
    }
  }
`)

/**
 * Mutations
 */
export const CREATE_PRODUCT_SUBTYPE = gql(`
  mutation CreateProductSubType($input: CreateProductSubType!) {
    createProductSubType(input: $input) {
      ...productSubType
    }
  }
`)

export const UPDATE_PRODUCT_SUBTYPE = gql(`
  mutation UpdateProductSubType($id: String!, $input: UpdateProductSubType!) {
    updateProductSubType(id: $id, input: $input) {
      ...productSubType
    }
  }
`)

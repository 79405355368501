require('dayjs/locale/th')
import BigNumber from 'bignumber.js'
import dayjs, { Dayjs } from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { isNull } from 'lodash'

import { ApolloError } from '@apollo/client'

import { MarketplaceSource } from './constants'

dayjs.extend(relativeTime)
dayjs.extend(buddhistEra)
dayjs.extend(duration)

export const formatNumberWithDecimal = (number) => {
  return number
    ?.toString()
    ?.replace(/[^0-9\.]+/g, '')
    ?.replace(/([^\d]*)(\d*(\.\d{0,2})?)(.*)/, '$2')
    ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const formatNumber = (number) => {
  return Number(number)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const formatNumberNoDecimal = (number) => {
  return Number(number)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const formatPercentage = (number) =>
  Number(number).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })

export const formatPercentageNoFraction = (number) =>
  Number(number).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0 })

export const formatPhoneNumberBySource = (str: string, countryCode: string, source: string) => {
  switch (source) {
    case MarketplaceSource.SHOPEE:
      return formatPhoneNumber(str.replace(countryCode, '0'))
    case MarketplaceSource.LAZADA:
    default:
      return formatPhoneNumber(str)
  }
}

export const formatPhoneNumber = (str: string) => {
  //Filter only numbers from the input
  const cleaned = ('' + str).replace(/\D/g, '')

  //Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3]
  } else return str
}

export const getRelativeTime = (date: string): string => dayjs(date).locale('th').fromNow()

export const formatThaiTable = (date: string) => dayjs(date).locale('th').format('DD MMM BBBB - HH:mm')

export const formatDateNoTime = (date: string): string => {
  return dayjs(date).format('YYYY-MM-DD')
}
export const formatThaiDate = (date: string, shortVer = false, includeTime = false): string => {
  return includeTime
    ? `${dayjs(date)
        .locale('th')
        .format(shortVer ? 'D MMM BBBB' : 'D MMMM BBBB')} เวลา ${dayjs(date).format('HH:mm')}`
    : `${dayjs(date)
        .locale('th')
        .format(shortVer ? 'D MMM BBBB' : 'D MMMM BBBB')}`
}

export const calculateDuration = (
  sendDate: string,
  adjustmentDate: string,
  format: 'humanize' | 'days' = 'days'
): string | number => {
  const sendDateParsed = dayjs(sendDate)
  const adjustmentDateParsed = dayjs(adjustmentDate)
  if (format === 'humanize') {
    // Calculate the duration in human-readable format
    const diff = dayjs.duration(sendDateParsed.diff(adjustmentDateParsed)).locale('th')
    return diff.humanize()
  } else {
    // Calculate the difference in days
    const diffInDays = sendDateParsed.diff(adjustmentDateParsed, 'day')
    return diffInDays
  }
}
export const formatThaiDateWithDayOfWeek = (date: string, shortVer = false, includeTime = false): string => {
  return includeTime
    ? `${dayjs(date)
        .locale('th')
        .format(shortVer ? 'dddd D MMM BBBB' : 'dddd D MMMM BBBB')} เวลา ${dayjs(date).format('HH:mm')}`
    : `${dayjs(date)
        .locale('th')
        .format(shortVer ? 'dddd D MMM BBBB' : 'dddd D MMMM BBBB')}`
}

export const formatThaiTime = (date: string): string => {
  let value = dayjs(date).locale('th').format('HH:mm')

  if (Number(value?.split(':')?.[0]) > 12) return `${value} น.`
  else return `${value} เช้า.`
}

export const formatThaiDateNoYear = (date: string, shortVer = false, includeTime = false): string => {
  return includeTime
    ? `${dayjs(date)
        .locale('th')
        .format(shortVer ? 'DD MMM' : 'DD MMMM')} ${dayjs(date).format('HH:mm:ss')}`
    : `${dayjs(date)
        .locale('th')
        .format(shortVer ? 'DD MMM' : 'DD MMMM')}`
}

export const formatThaiRelativeDate = (date: string) => {
  return dayjs(date).locale('th').fromNow()
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const snakeToCamel = (str) => str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase())

export const toSnakeCase = (str) => {
  return str
    ?.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    ?.join('_')
}

export const formatLimitNumber = (limitNumber: number, number: number) => {
  return number > limitNumber ? `${limitNumber}+` : number
}

export const extractGQLErrorMessage = (error: ApolloError) => {
  const { 1: errorMessage } = error.message.split('GraphQL error: ')

  return errorMessage
}

export const extractGQLApolloErrorMessage = (error: ApolloError) => {
  let errorMessage
  ;[errorMessage = ''] = error.message.split('ApolloError: ')

  if (errorMessage.includes('E11000')) {
    errorMessage = 'เนื่องจากรหัสหมวดหมู่ซ้ำ'
  }

  return errorMessage
}

export const extractExceptionErrorMessage = (error: ApolloError) => {
  const [exception, ...rest] = error.message.split(':')

  if (exception?.toLocaleLowerCase()?.includes('exception')) {
    return rest?.join(':') || error.message
  }

  return error.message
}

export const removeSpecialCharactor = (value: string) => {
  return value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
}

export const cleanString = (value: string) => {
  return value?.trim()?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
}

interface IformatFullAddress {
  address?: string
  province?: string
  district?: string
  subDistrict?: string
  zipcode?: string
}

export const formatFullAddress = ({
  address = '',
  province = '',
  district = '',
  subDistrict = '',
  zipcode = ''
}: IformatFullAddress) => {
  let fullAddress = ''
  if (address) fullAddress += address
  if (subDistrict) fullAddress += ` ต.${subDistrict}`
  if (district) fullAddress += ` อ.${district}`
  if (province) fullAddress += ` จ.${province}`
  if (zipcode) fullAddress += ` ${zipcode}`
  return fullAddress
}

export const formatAbnormalNumber = (number: any): number => {
  if (new Set([Infinity, NaN, null, undefined]).has(parseInt(number))) return 0

  return new BigNumber(number).toNumber()
}

/** Return date string in "YYYY-MM-DD" format */
export const transformDateFormat = (date: string): string | null => {
  if (isNull(date)) {
    return null
  }
  // Define a regular expression pattern to match "YYYY-MM-DD" format
  const yyyyMmDdRegex = /^(\d{4})-(\d{2})-(\d{2})$/

  // Define a regular expression pattern to match "DD/MM/YY" format
  const ddMmYyRegex = /^(\d{2})\/(\d{2})\/(\d{2})$/

  // Define a regular expression pattern to match "DD/MM/YYYY" format
  const ddMmYyyyRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/

  // Define a regular expression pattern to match "D/MM/YYYY" format
  const dMmYyyyRegex = /^(\d)\/(\d{2})\/(\d{4})$/

  // Define a regular expression pattern to match "DD-MM-YY" format
  const ddMmYyHyphenRegex = /^(\d{2})-(\d{2})-(\d{2})$/

  // Define a regular expression pattern to match "DD-M-YYYY" format
  const ddMYyyyRegex = /^(\d{2})-(\d)-(\d{4})$/

  // Define a regular expression pattern to match "DD/M/YYYY" format
  const ddMyyyyRegex = /^(\d{2})\/(\d{1})\/(\d{4})$/
  if (yyyyMmDdRegex.test(date)) {
    // If the input date matches "YYYY-MM-DD" format, transform it to "YYYY-MM-DD"
    return date
  } else if (ddMmYyRegex.test(date)) {
    // If the input date matches "DD/MM/YY" format, transform it to "YYYY-MM-DD"
    const match = date.match(ddMmYyRegex)
    if (match) {
      const year = `20${match[3]}`
      const month = match[2]
      const day = match[1]
      return `${year}-${month}-${day}`
    }
  } else if (ddMmYyyyRegex.test(date)) {
    // If the input date matches "DD/MM/YYYY" format, transform it to "YYYY-MM-DD"
    const match = date.match(ddMmYyyyRegex)
    if (match) {
      const year = match[3]
      const month = match[2]
      const day = match[1]
      return `${year}-${month}-${day}`
    }
  } else if (dMmYyyyRegex.test(date)) {
    // If the input date matches "D/MM/YYYY" format, transform it to "YYYY-MM-DD"
    const match = date.match(dMmYyyyRegex)
    if (match) {
      const year = match[3]
      const month = match[2]
      const day = match[1].padStart(2, '0') // Pad with '0' if less than 10
      return `${year}-${month}-${day}`
    }
  } else if (ddMmYyHyphenRegex.test(date)) {
    // If the input date matches "DD-MM-YY" format, transform it to "YYYY-MM-DD"
    const match = date.match(ddMmYyHyphenRegex)
    if (match) {
      const year = `20${match[3]}`
      const month = match[2]
      const day = match[1]
      return `${year}-${month}-${day}`
    }
  } else if (ddMYyyyRegex.test(date)) {
    // If the input date matches "DD-M-YYYY" format, transform it to "YYYY-MM-DD"
    const match = date.match(ddMYyyyRegex)
    if (match) {
      const year = match[3]
      const month = match[2].padStart(2, '0')
      const day = match[1]
      return `${year}-${month}-${day}`
    }
  } else if (ddMyyyyRegex.test(date)) {
    // If the input date matches "DD/M/YYYY" format, transform it to "YYYY-MM-DD"
    const match = date.match(ddMyyyyRegex)
    if (match) {
      const year = match[3]
      const month = match[2].padStart(2, '0')
      const day = match[1].padStart(2, '0')
      return `${year}-${month}-${day}`
    }
  }

  // If the input date doesn't match any of the recognized formats, return null or handle as needed.
  return null
}

export const getDifference = (obj1: Record<string, any>, obj2: Record<string, any>): Record<string, any> => {
  const diff = {}

  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      diff[key] = obj2[key]
    }
  }

  return diff
}

/** This function will convert a excel date to JS Date type
 *
 * Return Date
 */
export const serialDateToJSDate = (excelSerialDate: number): Date => {
  return new Date(Date.UTC(0, 0, excelSerialDate - 1))
}

export const flattenObject = (obj): { [key: string]: any } => {
  return Object.fromEntries(
    Object.entries(obj).flatMap(([key, value]) => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        return Object.entries(flattenObject(value)).map(([nestedKey, nestedValue]) => [
          `${key}.${nestedKey}`,
          nestedValue
        ])
      } else {
        return [[key, value]]
      }
    })
  )
}

/**
 * Input: [dayjs('2023-01-01'), dayjs('2023-01-02'), dayjs('2023-01-05')]
 * Output: "01-02 ม.ค. 66, 05 ม.ค. 66"
 */
export const formatDayjsArray = (dateInstances: Dayjs[]): string => {
  // Sort in ascending order
  const sortedDates = dateInstances.sort((a, b) => (a.isBefore(b) ? -1 : 1))

  // Handle single-element array
  if (sortedDates.length === 1) {
    return sortedDates[0].format('YYYY-MM-DD')
  }

  let resultString = ''
  let currentGroupStart = 0

  for (let i = 0; i < sortedDates.length - 1; i++) {
    const currentDate = sortedDates[i].format('YYYY-MM-DD')
    const nextDate = sortedDates[i + 1].format('YYYY-MM-DD')

    if (
      !dayjs(nextDate).isSame(dayjs(currentDate).add(1, 'day')) &&
      !dayjs(nextDate).isSame(dayjs(currentDate).subtract(1, 'day'))
    ) {
      // Append the previous group with a hyphen
      if (i > currentGroupStart) {
        resultString += `${sortedDates[currentGroupStart].format('DD')}-${dayjs(currentDate)
          .locale('th')
          .format('DD')} ${sortedDates[currentGroupStart].locale('th').format('MMM BB')},`
      } else {
        resultString += `${dayjs(currentDate).locale('th').format('D MMM BB')}, `
      }
      currentGroupStart = i + 1 // Start a new group
    }
  }

  // Handle the last group and the final date
  if (currentGroupStart < sortedDates.length - 1) {
    resultString += `${sortedDates[currentGroupStart].format('DD')}-${sortedDates[sortedDates.length - 1].format(
      'DD'
    )} ${sortedDates[currentGroupStart].locale('th').format('MMM BB')}`
  } else {
    resultString += sortedDates[sortedDates.length - 1].locale('th').format('D MMM BB')
  }
  return resultString
}

export function toPascalCase(str: string, replaceSeparator: string = '') {
  return str
    .split(replaceSeparator)
    .map((e) =>
      e
        .toLowerCase()
        .split(/\s+/)
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join('')
    )
    .join(' ')
}

/**
 *
 * @param num 12
 * @param len 4
 * @returns 0012
 */
export function generateLeadingZeroStringFromNumber(num: number, len: number): string {
  let str = num.toString()

  while (str.length < len) {
    str = '0' + str
  }

  return str
}

import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_LAZADA_CATEGORY_FEES = gql(`
  query GetLazadaCategoryFees(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: LazadaCategoryFeeFilterInput
  ) {
    lazadaCategoryFees(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...lazadaCategoryFee
    }

    categoriesMeta {
      total
    }
  }
`)

export const GET_LAZADA_CATEGORY_FEES_WITH_META = gql(`
  query GetLazadaCategoryFeesWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: LazadaCategoryFeeFilterInput
  ) {
    lazadaCategoryFeesWithMeta(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...lazadaCategoryFee
    }
    lazadaCategoryFeesMeta {
      total
    }
  }
`)

/**
 * Mutation
 */

export const CREATE_LAZADA_CATEGORY_FEE = gql(`
  mutation CreateLazadaCategoryFee($payload: LazadaCategoryFeeInput!) {
    createLazadaCategoryFee(payload: $payload) {
      ...lazadaCategoryFee
    }
  }
`)

export const UPDATE_LAZADA_CATEGORY_FEE = gql(`
  mutation UpdateLazadaCategoryFee($payload: LazadaCategoryFeeInput!, $params: FindOneLazadaCategoryFeeInput!) {
    updateLazadaCategoryFee(payload: $payload, params: $params) {
      ...lazadaCategoryFee
    }
  }
`)

export const DELETE_LAZADA_CATEGORY_FEE = gql(`
  mutation DeleteLazadaCategoryFee($_id: String!) {
    deleteLazadaCategoryFee(_id: $_id) {
      ...lazadaCategoryFee
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_SHOPEE_CATEGORY_FEES = gql(`
  query GetShopeeCategoryFees(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: ShopeeCategoryFeeFilterInput
  ) {
    shopeeCategoryFees(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...shopeeCategoryFee
    }

    categoriesMeta {
      total
    }
  }
`)

export const GET_SHOPEE_CATEGORY_FEES_WITH_META = gql(`
  query GetShopeeCategoryFeesWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: ShopeeCategoryFeeFilterInput
  ) {
    shopeeCategoryFeesWithMeta(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...shopeeCategoryFee
    }
    shopeeCategoryFeesMeta {
      total
    }
  }
`)

/**
 * Mutation
 */

export const CREATE__SHOPEE__CATEGORY__FEE = gql(`
  mutation CreateShopeeCategoryFee($payload: ShopeeCategoryFeeInput!) {
    createShopeeCategoryFee(payload: $payload) {
      ...shopeeCategoryFee
    }
  }
`)

export const UPDATE_SHOPEE_CATEGORY_FEE = gql(`
  mutation UpdateShopeeCategoryFee($payload: ShopeeCategoryFeeInput!, $params: FindOneCategoryFeeInput!) {
    updateShopeeCategoryFee(payload: $payload, params: $params) {
      ...shopeeCategoryFee
    }
  }
`)

export const DELETE_SHOPEE_CATEGORY_FEE = gql(`
  mutation DeleteShopeeCategoryFee($_id: String!) {
    deleteShopeeCategoryFee(_id: $_id) {
      ...shopeeCategoryFee
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

export const GET_MANY_STOCK_PRODUCT_WAREHOUSE = gql(`
  query GetManyStockProductWarehouse($filter: GetManyStockProductWarehouseFilterInput, $paginationLimit: Float, $paginationOffset: Float, $sort: SortInput) {
    getManyStockProductWarehouse(filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort) {
      data { 
        _id
        name
        address
        workspaceId
        createdById
        createdAt
        updatedAt
      }
      total
    }
  }
`)

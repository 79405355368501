import { gql } from 'graphql-generator/__generated__/gql'

export const GET_MANY_STOCK_PRODUCT_QRCODE = gql(`
  query GetManyStockProductQRCode($filter: StockProductQRCodeFilterInput!, $limit: Int, $offset: Int) {
    getManyStockProductQRCode(filter: $filter, limit: $limit, offset: $offset) {
      data {
        _id
        createdAt
        createdById
        inWarehouse
        lotNo
        lotQuantity
        printCount
        productImage
        productName
        productAlias
        productSku
        categoryId
        categoryName
        quantity
        runNo
        stockProductId
        unit
        unitValue
        updatedAt
        workspaceId
      }
      total
    }
  }
`)

export const GET_STOCK_PRODUCT_QRCODE = gql(`
  query GetStockProductQRCode($filter: StockProductQRCodeFilterInput!) {
    getStockProductQRCode(filter: $filter) {
      _id
      createdAt
      createdById
      inWarehouse
      lotNo
      lotQuantity
      printCount
      productImage
      productName
      productAlias
      productSku
      categoryId
      categoryName
      quantity
      runNo
      stockProductId
      unit
      unitValue
      updatedAt
      workspaceId
    }
  }
`)

export const CREATE_STOCK_PRODUCT_QRCODE = gql(`
  mutation CreateStockProductQRCode($input: CreateStockProductQRCodeInput!) {
    createStockProductQRCode(input: $input) {
      insertedCount
      lotNo
    }
  }
`)

export const CREATE_MANY_STOCK_PRODUCT_QRCODE = gql(`
  mutation CreateManyStockProductQRCode($input: CreateManyStockProductQRCodeInput!) {
    createManyStockProductQRCode(input: $input) {
      insertedCount
      lotNo
    }
  }
`)

export const UPDATE_MANY_QRCODE_PRINT_COUNT = gql(`
  mutation UpdateManyQRCodePrintCount($input: UpdateManyQRCodePrintCountInput!) {
    updateManyQRCodePrintCount(input: $input) {
      modifiedCount
    }
  }
`)

export const UPDATE_STOCK_PRODUCT_QRCODE = gql(`
  mutation UpdateStockProductQRCode($_id: String!, $input: UpdateStockProductQRCodeInput!) {
    updateStockProductQRCode(_id: $_id, input: $input) {
      _id
      createdAt
      createdById
      inWarehouse
      lotNo
      lotQuantity
      printCount
      productImage
      productName
      productAlias
      productSku
      quantity
      runNo
      stockProductId
      unit
      unitValue
      updatedAt
      workspaceId
    }
  }
`)

export const AUDIT_STOCK_PRODUCT_QR_CODES = gql(`
  mutation AuditStockProductQrCodes($input: AuditStockProductQrCodesInput!) {
    auditStockProductQrCodes(input: $input) {
      totalQuantity
      totalQuantityDifferent
    }
  }
`)

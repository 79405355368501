import { gql } from 'graphql-generator/__generated__/gql'

/* -------------------------------------------------------------------------- */
/*                                   Queries                                  */
/* -------------------------------------------------------------------------- */
export const GET_PRODUCT_TRACKING_LIST_WITH_META = gql(`
  query GetProductTrackingListWithMeta($filter: ProductTrackingFilterInput, $paginationOffset: Float, $paginationLimit: Float,  $sort: SortInput) {
    productTrackings(filter: $filter, paginationOffset:$paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
      ...productTracking
    }

    productTrackingMeta(filter: $filter) {
      total
    }
  }
`)

export const GET_PRODUCT_TRACKING_LIST_WITH_WAREHOUSE_WITH_META = gql(`
  query GetProductTrackingListWithWarehouseWithMeta($filter: ProductTrackingFilterInput, $paginationOffset: Float, $paginationLimit: Float,  $sort: SortInput) {
    productTrackingsWithWarehouse(filter: $filter, paginationOffset:$paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
      ...productTrackingWithWarehouse
    }

    productTrackingMeta(filter: $filter) {
      total
    }
  }
`)

export const GET_PRODUCT_TRACKING_LIST = gql(`
  query GetProductTrackingList($filter: ProductTrackingFilterInput, $paginationOffset: Float, $paginationLimit: Float,  $sort: SortInput) {
    productTrackings(filter: $filter, paginationOffset:$paginationOffset, paginationLimit: $paginationLimit, sort: $sort) {
      ...productTracking
    }
  }
`)

export const GET_PRODUCT_TRACKING_META = gql(`
  query GetProductTrackingMeta($filter: ProductTrackingFilterInput) {
    productTrackingMeta(filter: $filter) {
      total
    }
  }
`)

export const GET_PRODUCT_TRACKING_DETAILS = gql(`
  query GetProductTrackingDetails($filter: ProductTrackingFilterInput!) {
    productTracking(filter: $filter) {
      ...productTrackingDetail
    }
  }
`)

export const GET_PRODUCTION_ORDER_REPORT = gql(`
  query GetProductionOrderReport($filter: ProductionOrderReportFilter!) {
    productionOrderReport(filter: $filter) {
      staffId
      displayName
      data {
        product {
          ...leanProduct
        }
        quantity
        productTrackings {
          ...productTracking
        }
      }
    }
  }
`)

export const GET_PRODUCT_TRACKING_EXPORT_QC_DATA = gql(`
  query ProductTrackingsExportQCData(
    $filter: ProductTrackingExportDataFilterInput!,
    $sort: SortInput,
    $paginationOffset: Float,
    $paginationLimit: Float
  ) {
    productTrackingsExportQCData(
      filter: $filter, 
      sort: $sort, 
      paginationOffset: $paginationOffset,
      paginationLimit: $paginationLimit
    ) {
      uniqueId
      docId
      productSku
      productName
      productType
      qcStaffId
      qcStaffName
      assigneeStaffIds
      assigneeStaffNames
      assigneeStaffCount
      status
      openDate
      closeDate 
      note
      reasonToFix
      warehouse
      df {
        fleetId
        deliveryDate
        totalPackageSize
        totalReturnSize
        dfSourceOrderId
        dfStatus
        deliveryChannelName
        label
        zoneLabelOrder
        packageSize
        deliveryOptionDateOption
        formattedDeliveryOptionOption
        uniqueCount
        dfUniqueId
        dfProductSku
        dfTrackingNumber
      }
      order {
        orderSourceOrderId
        sourceStatus
        orderStatus
        user
        orderProduct
        category
        orderQuantity
        packageStatus
        packageSourceStatus
        packageReference
        orderTrackingNumber
        prePickTime
        preparedTime
        pickedTime
        printTime
        packedTime
        readyToShipTime
        waitForProductionTime
        madeToOrderTime
        boardedTime
        transportedTime
        deliveredTime
        returnedByDeliveryFirmTime
        returnTime
        fleetReadyTime
        fleetedTime
        cancelledTime
        doubtedTime
        fleetTime
        fleetStatus
        fleetDate
      }
    }
  }
`)

export const GET_PRODUCT_TRACKING_EXPORT_QC_META = gql(`
  query ProductTrackingsExportQCMeta($filter: ProductTrackingExportDataFilterInput!) {
    productTrackingsExportQCMeta(filter: $filter) {
      total
    }
  }
`)

export const GET_PRODUCT_TRACKING_EXPORT_QC_TRANSACTIONS = gql(`
  query ProductTrackingsExportQCTransactions(
    $filter: ProductTrackingExportDataFilterInput!,
    $sort: SortInput,
    $paginationOffset: Float,
    $paginationLimit: Float
  ) {
    productTrackingsExportQCTransactions(
      filter: $filter, 
      sort: $sort, 
      paginationOffset: $paginationOffset,
      paginationLimit: $paginationLimit
    ) {
      action
      activity
      uniqueId
      docId
      productSku
      productName
      productType
      qcStaffId
      qcStaffName
      assigneeStaffId
      assigneeStaffName 
      latestStatus 
      note
      createdAt
    }
  }
`)

export const GET_LEAN_PRODUCT_TRACKINGS_WITH_META = gql(`
  query GetLeanProductTrackingsWithMeta(
    $filter: ProductTrackingFilterInput
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
  ) {
    getLeanProductTrackingsWithMeta(
      filter: $filter
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
    ) {
      data {
        uniqueId
        status
        typeQRCode
        createdAt
        runningNumber
        lotQuantity
        createdBy {
          displayName
        }
        productActions {
          npDocId
          inventoryAdjustmentOrder {
            orderId
          }
        }
        warehouse {
          _id name
        }
        remainingQuantity
      }
      meta {
        total
      }
    }
  }
`)

/* -------------------------------------------------------------------------- */
/*                                  Mutations                                 */
/* -------------------------------------------------------------------------- */
export const ADD_MANY_PRODUCT_TRACKING = gql(`
  mutation AddManyProductTracking($input: AddManyProductTrackingInput!) {
    addManyProductTracking(input: $input) {
      ...productTracking
    }
  }
`)

export const UPDATE_MANY_ASSIGNEE = gql(`
  mutation UpdateManyAssignee($input: UpdateManyAssigneeInput!) {
    updateManyAssignee(input: $input) {
      ...productTracking
    }
  }
`)

export const DELETE_PRODUCT_TRACKING = gql(`
  mutation DeleteProductTracking($input: DeleteProductTrackingInput!) {
    deleteProductTracking(input: $input) {
      _id      
      uniqueId
    }
  }
`)

export const UPDATE_PRODUCT_ACTION = gql(`
  mutation UpdateProductAction($input: UpdateProductActionInput!) {
    updateProductAction(input: $input) {
      ...productTrackingUpdate
    }
  }
`)

export const ADD_MANY_PRODUCT_TRACKING_ACTION = gql(`
  mutation AddManyProductTrackingAction($inputs: [AddProductActionInput!]!) {
    addManyProductAction(inputs: $inputs) {
      ...productTracking
    }
  }
`)

export const ADD_MANY_PRODUCT_TRACKING_ACTION_AND_UPDATE_REMAINING_QUANTITY = gql(`
  mutation AddManyProductActionAndUpdateRemainingQuantity($inputs: [AddProductActionAndUpdateRemainingQuantityInput!]!) {
    addManyProductActionAndUpdateRemainingQuantity(inputs: $inputs) {
      ...productTracking
    }
  }
`)

export const UPDATE_MANY_PRODUCT_ACTIONS = gql(`
  mutation UpdateManyProductActions($input: UpdateManyProductActionInput!) {
    updateManyProductActions(input: $input) {
      ...productTrackingUpdate
    }
  }
`)

export const ASSIGN_MANY_PRODUCT_ACTIONS = gql(`
  mutation AssignManyProductActions($uniqueIds: [String!]!, $staff: String!) {
    assignManyProductActions(uniqueIds: $uniqueIds, staff: $staff) {
      ...productTrackingUpdate
    }
  }
`)

export const ASSIGN_MANY_PRODUCT_ACTIONS_TO_MANY_ASSIGNEES = gql(`
  mutation AssignManyProductActionsToManyAssignees($uniqueIds: [String!]!, $assigneeIds: [String!]!,$fromStaff: String) {
    assignManyProductActionsToManyAssignees(uniqueIds: $uniqueIds, assigneeIds: $assigneeIds, fromStaff: $fromStaff) {
      ...productTrackingUpdate
    }
  }
`)

export const CLOSE_MANY_PRODUCT_ACTIONS = gql(`
  mutation CloseManyProductActions($uniqueIds: [String!]!, $fromStaff: String) {
    closeManyProductActions(uniqueIds: $uniqueIds, fromStaff: $fromStaff) {
      ...productTrackingUpdate
    }
  }
`)

export const DAMAGE_MANY_PRODUCT_ACTIONS = gql(`
  mutation DamageManyProductActions($uniqueIds: [String!]!, $fromStaff: String) {
    damageManyProductActions(uniqueIds: $uniqueIds, fromStaff: $fromStaff) {
      ...productTrackingUpdate
    }
  }
`)

export const FIXABLE_MANY_PRODUCT_ACTIONS = gql(`
  mutation FixableManyProductActions($uniqueIds: [String!]!, $fromStaff: String, $note: String) {
    fixableManyProductActions(uniqueIds: $uniqueIds, fromStaff: $fromStaff, note: $note) {
      ...productTrackingUpdate
    }
  }
`)

export const ASSIGN_PRODUCT_TRACKINGS = gql(`
  mutation AssignProductTrackings($input: AssignProductTrackingsInput!) {
    assignProductTrackings(input: $input) {
      result
      data {
        ...productTrackingUpdate
      }
      message
      ongoingTasks
    }
  }
`)

export const APPROVE_QC_PRODUCT_TRACKINGS = gql(`
  mutation ApproveQcProductTrackings($input: ApproveQcProductTrackingsInput!) {
    approveQcProductTrackings(input: $input) {
      ...productTrackingUpdate
    }
  }
`)

export const ASSEMBLE_PRODUCT_TRACKINGS = gql(`
  mutation AssembleProductTrackings($input: AssembleProductTrackingsInput!) {
    assembleProductTrackings(input: $input) {
      ...productTracking
    }
  }
`)

export const IMPORT_TO_INVENTORY_PRODUCT_TRACKINGS = gql(`
  mutation ImportToInventoryProductTrackings($input: ImportToInventoryProductTrackingsInput!) {
    importToInventoryProductTrackings(input: $input) {
      ...productTrackingUpdate
    }
  }
`)

export const CANCEL_PRODUCT_TRACKINGS = gql(`
  mutation CancelProductTrackings($input: CancelProductTrackingsInput!) {
    cancelProductTrackings(input: $input) {
      uniqueId
    }
  }
`)

export const DELETE_PRODUCT_TRACKING_ACTIVITIES = gql(`
  mutation DeleteProductTrackingActivities($input: DeleteProductTrackingActivitiesInput!) {
    deleteProductTrackingActivities(input: $input) {
      rowAffected
    }
  }
`)

export const ASSIGN_MANY_PRODUCT_TRACKING_TO_STAFF = gql(`
  mutation AssignManyProductTrackingToStaff($input: [AssignManyProductTrackingToStaffInput!]!) {
    assignManyProductTrackingToStaff(input: $input) {
      ...productTracking
    }
  }
`)

export const COPY_PRODUCT_TRACKING = gql(`
  mutation CopyProductTracking($columns: [String!]!, $filter: ProductTrackingFilterInput!) {
    copyProductTracking(columns: $columns, filter: $filter)
  }
`)

export const GET_WAREHOUSE_QRCODES_WITH_META = gql(`
  query GetWarehouseQRCodesWithMeta(
    $filter: WarehouseQRCodeFilterInput!
    $type: String
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
  ) {
    getWarehouseQRCodesWithMeta(
      filter: $filter
      type: $type
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
    ) {
      data {
        uniqueId
        status
        typeQRCode
        createdAt
        runningNumber
        lotQuantity
        createdBy {
          displayName
        }
        productActions {
          npDocId
          inventoryAdjustmentOrder {
            orderId
          }
          pickingList {
            listId
          }
        }
        pickingListId
      }
      meta {
        total
      }
    }
  }
`)

export const UPDATE_WIP_ACTION = gql(`
  mutation UpdateWIPAction($uniqueId: String!, $activity: String!) {
    updateWIPAction(uniqueId: $uniqueId, activity: $activity) {
      uniqueId
    }
  }
`)

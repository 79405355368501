import { gql } from 'graphql-generator/__generated__/gql'

export const GET_SALES_GRAPH_SUMMARY = gql(`
  query GetSalesGraphSummary($input: SalesGraphFilterInput!) {
    getSalesGraphSummary(input: $input) {
      id
      graphData {
        x
        y
      }
      totalSales
      numberOfOrders
      salesBreakdown {
        expenseBreakdown
        cancelReasonBreakdown
        totalConfirmedSales
        shippingFeeBreakdown {
          estimate
          actual
        }
        totalCancelledSales
      }
    }
  }
`)
export const GET_SHORT_STOCK_REPORT_WITH_META = gql(`
  query GetShortStockReportWithMeta(
    $input: ShortStockFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getShortStockReport(
      input: $input
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      data {
        _id
        salesCount
        salesCountPerPeriod
        salesValue
        salesValuePerPeriod
        periodsShortStock
        product {
          _id
          name
          sku
          status
          type
          productActiveLatest
          inventories {
            actualAmount
            potentialAmount
            availableAmount
          }
          images {
            src
          }
        }
      }
      meta {
        count
      }
    }
  }
`)
export const GET_DEAD_STOCK_REPORT_WITH_META = gql(`
  query GetDeadStockReportWithMeta(
    $input: DeadStockFilterInput!
    $sort: SortInput
    $paginationOffset: Float
    $paginationLimit: Float
  ) {
    getDeadStockReport(
      input: $input
      sort: $sort
      paginationLimit: $paginationLimit
      paginationOffset: $paginationOffset
    ) {
      _id
      sku
      name
      images {
        src
      }
      productActiveLatest
    }

    getDeadStockReportMeta(filter: $input) {
      count
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

export const GET_DELIVERY_CHANNELS = gql(`
  query GetDeliveryChannels(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: DeliveryChannelFilterInput
  ) {
    deliveryChannels(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...deliveryChannel
    }
  }
`)

export const GET_DELIVERY_CHANNELS_WITH_META = gql(`
  query GetDeliveryChannelsWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: DeliveryChannelFilterInput
  ) {
    deliveryChannels(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...deliveryChannel
    }

    deliveryChannelsMeta(filter: $filter) {
      total
    }
  }
`)

export const CREATE_DELIVERY_CHANNEL = gql(`
  mutation CreateDeliveryChannel($input: DeliveryChannelInput!) {
    createDeliveryChannel(input: $input) {
      ...deliveryChannel
    }
  }
`)

export const UPDATE_DELIVERY_CHANNEL = gql(`
  mutation UpdateDeliveryChannel($input: DeliveryChannelInput!, $id: String!) {
    updateDeliveryChannel(input: $input, id: $id) {
      ...deliveryChannel
    }
  }
`)

export const UPDATE_DELIVERY_CHANNEL_ORDER = gql(`
  mutation UpdateDeliveryChannelOrder($input: [DeliveryChannelInput!]!) {
    updateDeliveryChannelOrder(input: $input) {
      ...deliveryChannel
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

export const GET_SALES = gql(`
  query GetSales($filter: SalesLeadFilterInput, $sort: SortInput, $paginationOffset: Float, $paginationLimit: Float) {
    salesLeads(filter: $filter, sort: $sort, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset) {
      ...salesLead
    }
  }
`)
export const GET_SALES_META = gql(`
  query GetSalesMeta($filter: SalesLeadFilterInput) {
    salesLeadMeta(filter: $filter) {
      all
      bought
      boughtOtherChannel
      draft
      waitToDecide
      askQuestion
      lineMenu
      blocked
    }
  }
`)

export const CREATE_SALESLEAD = gql(`
  mutation CreateSalesLead($input: SalesLeadInput!) {
    createSalesLead(input: $input) {
      ...salesLead
    }
  }
`)
export const UPDATE_SALESLEAD = gql(`
  mutation UpdateSalesLead($input: SalesLeadInput!, $id: String!) {
    updateSalesLead(input: $input, id: $id) {
      ...salesLead
    }
  }
`)

export const GET_SALESLEAD_ONE = gql(`
  query GetSalesLeadOne($params: FindOneSalesLeadInput!) {
    salesLead(params: $params) {
      ...salesLead
    }
  }
`)

export const UPDATE_SALESLEAD_STATUS = gql(`
  mutation UpdateSalesLeadStatus($input: SalesLeadStatusInput!, $id: String!) {
    updateSalesLeadStatus(input: $input, id: $id) {
      ...salesLead
    }
  }
`)

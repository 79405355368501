import { gql } from 'graphql-generator/__generated__/gql'

/**
 * Query
 */

export const GET_PRODUCTION_LINE_UNIT = gql(`
  query GetProductionLineUnit($filter: ProductionLineUnitFilter!) {
    getProductionLineUnit(filter: $filter) {
      ...productionLineUnit
    }
  }
`)

export const GET_MANY_PRODUCTION_LINE_UNIT = gql(`
  query GetManyProductionLineUnit(
    $filter: ProductionLineUnitFilter
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
  ) {
    getManyProductionLineUnit(
      filter: $filter
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
    ) {
      ...productionLineUnit
    }
  }
`)

export const GET_PRODUCTION_LINE_UNIT_OPTION = gql(`
  query GetProductionLineUnitOption {
    getProductionLineUnitOption {
      skuPrefix
      unit
    }
  }
`)

/**
 * Query
 */

export const CREATE_PRODUCTION_LINE_UNIT = gql(`
  mutation CreateProductionLineUnit($input: ProductionLineUnitInput!) {
    createProductionLineUnit(input: $input) {
      ...productionLineUnit
    }
  }
`)

export const CREATE_MANY_PRODUCTION_LINE_UNIT = gql(`
  mutation CreateManyProductionLineUnit($input: [ProductionLineUnitInput!]!) {
    createManyProductionLineUnit(input: $input) {
      ...productionLineUnit
    }
  }
`)

export const UPDATE_MANY_PRODUCTION_LINE_UNIT = gql(`
  mutation UpdateManyProductionLineUnit($input: [UpdateProductionLineUnitInput!]!) {
    updateManyProductionLineUnit(input: $input) {
      ...productionLineUnit
    }
  }
`)

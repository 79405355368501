import { gql } from 'graphql-generator/__generated__/gql'

export const GET_MANY_STOCK_PRODUCT_TRANSACTION = gql(`
  query GetManyStockProductTransaction(
    $filter: GetManyStockProductTransactionFilterInput
    $limit: Int
    $offset: Int
    $sort: SortInput
  ) {
    getManyStockProductTransaction(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
      data {
        _id
        stockProductId
        stockProductQRCodeId
        lotNo
        runNo
        lotQuantity
        action
        productSku
        productImage
        productName
        productAlias
        categoryId
        categoryName
        quantity
        unitValue
        unit
        note
        workspaceId
        createdById
        createdAt
        updatedAt
      }
      total
    }
  }
`)

export const CREATE_MANY_STOCK_PRODUCT_TRANSACTION = gql(`
  mutation CreateManyStockProductTransaction($input: CreateTransactionInput!) {
    createManyStockProductTransaction(input: $input) {
      results {
        description
        hasError
        lotNo
        lotQuantity
        message
        runNo
      }
    }
  }
`)

export const CREATE_NO_VALIDATE_TX = gql(`
  mutation CreateNoValidateTx($input: CreateNoValidateTxInput!) {
    createNoValidateTx(input: $input) {
      data {
        message
      }
    }
  }
`)

export const REVALIDATE_PRODUCT_TRANSACTION = gql(`
  mutation RevalidateProductTransaction($stockProductId: String!) {
    revalidateProductTransaction(stockProductId: $stockProductId)
  }
`)

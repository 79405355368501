import { gql } from 'graphql-generator/__generated__/gql'

export const GET_ROLE = gql(`
  query GetRole($_id: String!) {
    role(_id: $_id) {
      ...role
    }
  }
`)

export const GET_ROLES = gql(`
  query GetRoles($paginationOffset: Float, $paginationLimit: Float, $sort: SortInput, $filter: RoleFilterInput) {
    roles(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort, filter: $filter) {
      ...role
      totalMembers
    }
  }
`)

export const GET_ROLES_WITH_META = gql(`
  query GetRolesWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: RoleFilterInput
  ) {
    roles(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort, filter: $filter) {
      ...role
      totalMembers
    }

    rolesMeta(filter: $filter) {
      total
    }
  }
`)

export const ROLE_CREATE = gql(`
  mutation CreateRole($input: RoleInput!) {
    createRole(input: $input) {
      ...role
    }
  }
`)

export const ROLE_UPDATE = gql(`
  mutation UpdateRole($input: RoleInput!, $id: String!) {
    updateRole(input: $input, id: $id) {
      ...role
    }
  }
`)

export const DELETE_ROLE = gql(`
  mutation DeleteRole($id: String!) {
    deleteRole(id: $id) {
      _id
    }
  }
`)

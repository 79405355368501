import { gql } from 'graphql-generator/__generated__/gql'

export const GET_VEHICLE = gql(`
  query GetVehicle($license: String!) {
    getVehicle(license: $license) {
      ...vehicle
    }
  }
`)

export const GET_MANY_VEHICLE_WITH_META = gql(`
  query GetManyVehicle(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: VehicleFilterInput
  ) {
    getManyVehicle(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      ...vehicle
    }

    getVehicleMeta(filter: $filter) {
      total
    }
  }
`)

export const CREATE_VEHICLE = gql(`
  mutation CreateVehicle($input: VehicleInput!) {
    createVehicle(input: $input) {
      ...vehicle
    }
  }
`)

export const UPDATE_VEHICLE = gql(`
  mutation UpdateVehicle($license: String!, $input: VehicleUpsertInput!) {
    updateVehicle(license: $license, input: $input) {
      ...vehicle
    }
  }
`)

export const DELETE_VEHICLE = gql(`
  mutation DeleteVehicle($license: String!) {
    deleteVehicle(license: $license) {
      ...vehicle
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

export const GET_STOCK_PRODUCT_USER_LOGGED_IN = gql(`
  query GetStockProductUserLoggedIn {
    getStockProductUserLoggedIn {
        _id
        name
        email
        photoURL
        config {
          selectedWarehouseId
        }
        createdById
        createdAt
        updatedAt
    }
  }
`)

export const CHANGE_STOCK_PRODUCT_WAREHOUSE = gql(`
mutation ChangeStockProductWarehouse($input: UpdateStockProductWarehouseInput!){ 
  changeStockProductWarehouse(input: $input) {
    _id
    config{
      selectedWarehouseId
    }
  }
}
`)

import { gql } from 'graphql-generator/__generated__/gql'

export const GET_WORKSPACES = gql(`
  query GetWorkspaces($filter: WorkspaceFilterInput) {
    workspaces(filter: $filter) {
      ...authWorkspace
    }
  }
`)

export const CREATE_WORKSPACE = gql(`
  mutation CreateWorkspace($input: WorkspaceInput!) {
    createWorkspace(input: $input) {
      ...authWorkspace
    }
  }
`)

export const UPDATE_WORKSPACE = gql(`
  mutation UpdateWorkspace($input: WorkspaceInput!, $id: String!) {
    updateWorkspace(input: $input, id: $id) {
      ...authWorkspace
    }
  }
`)

export const GET_WORKSPACE_MEMBERS = gql(`
  query GetWorkspaceMembers(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: WorkspaceMemberFilterInput
  ) {
    workspaceMembers(
      paginationOffset: $paginationOffset
      paginationLimit: $paginationLimit
      sort: $sort
      filter: $filter
    ) {
      workspace {
        ...authWorkspace
      }
      user {
        ...user
      }
      roles {
        workspaceId
        name
        description
        _id
      }
      ...workspaceMember
    }

    workspaceMembersMeta(filter: $filter) {
      total
    }
  }
`)

export const GET_WORKSPACE_MEMBER = gql(`
  query GetWorkspaceMember($id: String!) {
    workspaceMember(id: $id) {
      workspace {
        ...authWorkspace
      }
      user {
        ...user
      }
      roles {
        workspaceId
        name
        description
        permissions {
          feature
          actions
        }
        _id
      }
      ...workspaceMember
    }
  }
`)

export const UPDATE_WORKSPACE_MEMBER = gql(`
  mutation UpdateWorkspaceMember($input: WorkspaceMemberInput!, $id: String!) {
    updateWorkspaceMember(input: $input, id: $id) {
      workspace {
        ...authWorkspace
      }
      user {
        ...user
      }
      roles {
        workspaceId
        name
        description
        _id
      }
      ...workspaceMember
    }
  }
`)

export const DELETE_WORKSPACE_MEMBER = gql(`
  mutation DeleteWorkspaceMember($id: String!) {
    deleteWorkspaceMember(id: $id) {
      workspace {
        ...authWorkspace
      }
      user {
        ...user
      }
      roles {
        workspaceId
        name
        description
        _id
      }
      ...workspaceMember
    }
  }
`)

export const UPDATE_STATUS_INTEGRATION_SERVICE = gql(`
  mutation UpdateStatusIntegrationService(
    $isActive: Boolean!
    $orderSourceId: String!
    $sourceType: String!
    $shopId: String!
  ) {
    updateStatusIntegrationService(
      isActive: $isActive
      orderSourceId: $orderSourceId
      sourceType: $sourceType
      shopId: $shopId
    ) {
      ...leanOrderSource
    }
  }
`)

export const FIND_QR_CODE_INTEGRATION_BANK_ACCOUNTS = gql(`
  query FindQRCodeIntegrationBankAccounts() {
    findQRCodeIntegrationBankAccounts() {
      ...bankaccounts
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

export const ORDER_PICKING_ITEM_WITH_CATEGORY = gql(`
  fragment orderWithCategory on Order {
    _id
    sourceStatus
    source
    sourceRef {
      name
    }
    sourceOrderId
    sourceUpdatedTime
    sourceCreatedTime
    note
    recipientAddress {
      name
      phone
      address
      province
      district
      country
      zipcode
    }
    itemMeta {
      _id
      taxOption
      tax
      discount
      totalAmountBeforeTax
      taxAmount
      totalAmountAfterTax
      estShippingFeeIncludeTax
      estShippingFeeBeforeTax
      estShippingFeeAfterTax
      actualShippingFeeIncludeTax
      actualShippingFeeBeforeTax
      actualShippingFeeAfterTax
      finalAmount
      note
    }
    items {
      productId
      product {
        _id
      }
      category {
        name
        currentPath
        parentPath
      }
      productName
      productImage
      productSku
      quantity
      price
      discount
    }
    packages {
      _id
      items {
        product {
          _id
          sku
          name
          productAlias
          type
          ratio {
            product {
              _id
              sku
              name
              productAlias
              type
            }
            productSku
            productName
            type
            amount
          }
        }
        productName
        productSku
        productImage
        quantity
        onePerPackage
        productPrice
      }
      status
      sourceStatus
      shippingLabel
      statusMeta {
        pickedTime
        printedTime
        packedTime
        boardedTime
        transportedTime
        deliveredTime
      }
      trackingNumber
      shippingLabel
      deliveryChannelId
      codAmount
      reference
    }

    isUrgentOrder
    deliveryChannel {
      _id
      name
      key
      type
      abbreviation
    }
    status
    shippingLabel
    payment {
      status
    }
  }

  fragment orderPickingItem on OrderPickingItem {
    product
    category {
      _id
      name
      currentPath
      parentPath
    }
    image
    sku
    name
    quantity
    orders {
      ...orderWithCategory
    }
  }
`)

export const GET_PICKING_LIST_META = gql(`
  query GetOrderItemPickingListMeta($filter: OrderFilterInput!) {
    orderItemPickingListMeta(filter: $filter) {
      all
      single
      rushSingle
      multiple
      rushMultiple
    }
  }
`)

export const PICKING_LIST_ITEM_FULL_ORDER = gql(`
  fragment pickingListItemFullOrder on PickingListItem {
    product {
      _id
      type
    }
    image
    sku
    name
    quantity
    productPosition
    ratio {
      productId
      image
      sku
      name
      quantity
    }
    orders {
      deliveryCaution {
          isCaution
          note
          updatedAt
      }
      order {
        ...leanOrder
      }
      sourceOrderId
      sourceCreatedTime
      quantity
      source
      packingType
      deliveryChannel
      parentSku
      isUrgentOrder
    }
  }
`)

export const PICKING_LIST_ITEM = gql(`
  fragment pickingListItem on PickingListItem {
    product{
      _id
      type
    }
    image
    sku
    name
    quantity
    productPosition
    ratio {
      productId
      image
      sku
      name
      quantity
    }
    orders {
      deliveryCaution {
          isCaution
          note
          updatedAt
        }
      sourceOrderId
      sourceCreatedTime
      quantity
      source
      packingType
      deliveryChannel
      parentSku
      isUrgentOrder
    }
  }
`)

export const LEAN_PICKING_LIST = gql(`
  fragment leanPickingList on PickingList {
    _id
    all {
      ...pickingListItem
    }
    single {
      ...pickingListItem
    }
    multiple {
      ...pickingListItem
    }
    listId
    status
    statusMeta {
      prepickTime
      prepickBy {
        ...user
      }
      pickedTime
      pickedBy {
        ...user
      }
      cancelledTime
      cancelledBy {
        ...user
      }
    }
    createdBy {
      ...user
    }
    documentOverview{
      ...documentOverview
    }
    createdAt
    updatedAt
    warehouse {
      _id
      name
    }
  }
`)

export const PICKING_LIST = gql(`
  fragment pickingList on PickingList {
    _id
    all {
      ...pickingListItemFullOrder
    }
    single {
      ...pickingListItemFullOrder
    }
    multiple {
      ...pickingListItemFullOrder
    }
    listId
    status
    statusMeta {
      prepickTime
      prepickBy {
        ...user
      }
      pickedTime
      pickedBy {
        ...user
      }
      cancelledTime
      cancelledBy {
        ...user
      }
    }
    createdBy {
      ...user
    }
    unfilledProducts{
      productId
      productImage
      productSku
      productName
      quantity
      status
    }
    createdAt
    updatedAt
    warehouse {
      _id
      name
    }
  }
`)

export const GET_PICKING_LISTS = gql(`
  query GetPickingLists(
    $filter: PickingListFilterInput
    $paginationLimit: Float
    $paginationOffset: Float
    $sort: SortInput
  ) {
    pickingLists(filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort) {
      ...leanPickingList
    }
  }
`)

export const GET_PICKING_LISTS_META = gql(`
  query GetPickingListsMeta($filter: PickingListFilterInput) {
    pickingListsMeta(filter: $filter) {
      all
      unpick
      prepick
      prepared
      prepared_confirmed
      picked
      cancelled
    }
  }
`)

export const GET_PICKING_LIST = gql(`
  query GetPickingList($filter: PickingListFilterInput, $sort: SortInput) {
    pickingList(filter: $filter, sort: $sort) {
      ...pickingList
    }
  }
`)

export const SCAN_PICKING_LIST = gql(`
  mutation ScanPickingList($input: ScanPickingListInput!) {
    scanPickingList(input: $input) { 
      uniqueId
      productSku
    } 
  }
`)

export const GET_PICKING_DYNAMIC_LIST = gql(`
  query GetPickingListDynamic {
    pickingListDynamic {
      ...pickingList
    }
  }
`)

export const GET_ONE_N_PICKING_LIST_ORDERS = gql(`
  query GetOneNPickingListOrders($filter: OrderAdvancedSearchFilterInput!, $rushDate: Date) {
    pickingListOneNOrders(filter: $filter, rushDate: $rushDate) {
      rush {
        ...oneNPickingListOrder
      }
      normal {
        ...oneNPickingListOrder
      }
    }
  }
`)

export const UPDATE_PICKING_LIST_STATUS = gql(`
  mutation UpdatePickingListStatus($id: String!, $newStatus: String!) {
    updatePickingListStatus(id: $id, newStatus: $newStatus) {
      _id
      listId
    }
  }
`)

export const UPDATE_PICKING_LIST_STATUS_BY_SCAN = gql(`
  mutation UpdatePickingListStatusByScan(
    $input: UpdatePickingListStatusByScanInput!
  ) {
    updatePickingListStatusByScan(
      input: $input
    ) {
      _id
      listId
    }
  }
`)

export const CREATE_PICKING_LIST = gql(`
  mutation CreatePickingList($orderIds: [String!]!,$warehouseId: String!,$suffix: String, $isConvertConvertible: Boolean) {
    createPickingList(orderIds: $orderIds,warehouseId: $warehouseId ,suffix: $suffix, isConvertConvertible: $isConvertConvertible) {
      _id
      listId
    }
  }
`)

export const UPDATE_PICKING_LIST_FOR_LOW_PRIORITY_ORDERS = gql(`
  mutation UpdatePickingListForLowPriorityOrders(
    $insufficientProducts: [InsufficientProductInput!],
    $lotNo: String!,
    $lowPriorityOrderIds: [String!]!,
    $pickingListId: String!,
    $ordersWithQuantityNotes: [OrderWithQuantityNotes!]!
    $newStatus: String
  ) {
    updatePickingListForLowPriorityOrders(
      insufficientProducts: $insufficientProducts
      lowPriorityOrderIds: $lowPriorityOrderIds
      pickingListId: $pickingListId
      ordersWithQuantityNotes: $ordersWithQuantityNotes
      lotNo: $lotNo
      newStatus: $newStatus
    ) {
      ...leanPickingList
    }
  }
`)

export const UPDATE_PICKING_LIST_BY_LOW_PRIORITY_ORDERS = gql(`
  mutation UpdatePickingListByLowPriorityOrders($lowPriorityOrderIds: [String!]!, $pickingListId: String!) {
    updatePickingListByLowPriorityOrders(lowPriorityOrderIds: $lowPriorityOrderIds, pickingListId: $pickingListId) {
      ...pickingList
    }
  }
`)

import { gql } from 'graphql-generator/__generated__/gql'

export const GET_SINGLE_STAFF = gql(`
  query GetSingleStaff($filter: StaffFilterInput) {
    getSingleStaff(filter: $filter) {
      staffId
      displayName
      phoneNumber
      role{
        ...staffRole
      }
      warehouses {
        _id,
        name,
        usage
      }
      note
      user {
        _id
        uid
        displayName
        email
      }
      workspaceId
      _id
      createdAt
      updatedAt
    }
  }
`)

export const GET_MANY_STAFF_WITH_META = gql(`
  query GetManyStaffWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: StaffFilterInput
  ) {
    getManyStaff(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort, filter: $filter) {
      staffId
      displayName
      phoneNumber
      role{
        ...staffRole
      }
      note
      workspaceId
      _id
      createdAt
      updatedAt
    }

    getStaffMeta(filter: $filter) {
      total
    }
  }
  `)

export const GET_MANY_STAFF_WITH_ADVANCED_SEARCH_WITH_META = gql(`
  query GetManyStaffWithAdvancedSearchWithMeta(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: StaffAdvancedSearchFilter
  ) {
    getManyStaffWithAdvancedSearchWithMeta(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort, filter: $filter) {
      staffs {
        staffId
        displayName
        phoneNumber
        role{
          ...staffRole
        }
        note
        workspaceId
        _id
        createdAt
        updatedAt
      }
      meta {
        total
      }
    }
  }
  `)

export const GET_MANY_STAFF = gql(`
  query GetManyStaff {
    getManyStaff {
      staffId
      displayName
      phoneNumber
      role{
        ...staffRole
      }
      workspaceId
      _id
      createdAt
      updatedAt
    }
  }
`)

export const GET_STAFFS = gql(`
  query GetStaffs(
    $filter: StaffFilterInput
  ) {
    getStaffs(filter: $filter) {
      staffId
      displayName
      phoneNumber
      roleId
      workspaceId
      _id
      createdAt
      updatedAt
    }
  }
`)
export const GET_STAFF = gql(`
  query GetStaff(
    $filter: StaffFilterInput
  ) {
    getStaff(filter: $filter) {
      staffId
      displayName
      phoneNumber
      role {
        name
      }
      roleId
      workspaceId
      _id
      createdAt
      updatedAt
    }
  }
`)

export const GET_STAFF_CONTROL_ROOM = gql(`
  query GetStaffControlRoom( 
      $meta: String!
      $paginationOffset: Float
      $paginationLimit: Float
      $sort: SortInput
      $filter: StaffControlRoomFilterInput
    ){
    getStaffControlRoom(meta: $meta, filter: $filter, paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort){
      data{
        ...staffControlRoom
      }
    }
  }
`)

export const GET_STAFF_CONTROL_ROOM_META = gql(`
  query GetStaffControlRoomMeta( 
      $meta: String!
      $filter: StaffControlRoomFilterInput
    ){
    getStaffControlRoomMeta(meta: $meta, filter: $filter){
      meta{
        ...staffControlRoomMeta
      }
    }
  }
`)

export const GET_STAFF_JOB_DETAILS_REPORT = gql(`
  query GetStaffJobDetailsReport( 
      $filter: StaffDetailsReportInput
      $paginationOffset: Float
      $paginationLimit: Float
      $sort: SortInput
    ){
      getStaffJobDetailsReport(filter: $filter, paginationLimit: $paginationLimit, paginationOffset: $paginationOffset, sort: $sort) {
        _id
        activity
        createdAt
        createdById
        inventoryAdjustmentOrder{
          _id
          orderId
        }
        productId
        productName
        productSku
        productTrackingId
        staff{
          _id
          displayName
        }
        activityTime{
          assign_production_job
          wait_for_qc_job
          close_production_job
          defect_product
          import_to_inventory_job
        }
        status
        uniqueId
        updatedAt
        workspaceId
      }
    }
`)

export const GET_STAFF_JOB_DETAILS_REPORT_META = gql(`
  query GetStaffJobDetailsReportMeta( 
      $filter: StaffDetailsReportInput
    ){
      getStaffJobDetailsReportMeta(filter: $filter) {
       total {
        _id
        count
       }
       activityMeta { 
        _id
        count
       }
      }
    }
`)

export const CREATE_STAFF = gql(`
  mutation CreateStaff($input: StaffInput!) {
    createStaff(input: $input) {
      staffId
      displayName
      phoneNumber
      _id
      createdAt
      updatedAt
    }
  }
`)
export const UPDATE_STAFF = gql(`
  mutation UpdateStaff($id: String!, $input: StaffInput!) {
    updateStaff(id: $id, input: $input) {
      staffId
      displayName
      phoneNumber
      _id
      createdAt
      updatedAt
    }
  }
`)

export const DELETE_STAFF = gql(`
  mutation DeleteStaff($_id: String!) {
    deleteStaff(_id: $_id) {
      staffId
      displayName
      phoneNumber
      _id
      createdAt
      updatedAt
    }
  }
`)

export const GET_MANY_STAFF_ROLE = gql(`
  query GetManyStaffRole(    
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: StaffRoleFilterInput
  ){ 
  getManyStaffRole(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort, filter: $filter) {
    ...staffRole 
  }
}
`)

export const GET_STAFF_ROLE = gql(`
  query GetStaffRole($filter: StaffRoleFilterInput){ 
    getStaffRole(filter: $filter) {
      ...staffRole 
    }
  }
`)

export const GET_STAFF_ROLE_META = gql(`
  query GetStaffRoleMeta(    
    $filter: StaffRoleFilterInput
  ){ 
  getStaffRoleMeta(filter: $filter) {
    total
  }
}
`)

export const CREATE_STAFF_ROLE = gql(`
  mutation CreateStaffRole(    
    $input: StaffRoleInput!
  ){ 
  createStaffRole(input: $input){
    ...staffRole 
  }
}
`)

export const UPDATE_STAFF_ROLE = gql(`
  mutation UpdateStaffRole(    
    $id: String!
    $input: StaffRoleInput!
  ){ 
  updateStaffRole(id: $id, input: $input){
    ...staffRole 
  }
}
`)

export const DELETE_STAFF_ROLE = gql(`
  mutation DeleteStaffRole(    
    $_id: String!
  ){ 
  deleteStaffRole(_id: $_id){
    ...staffRole 
  }
}
`)

export const UPDATE_MANY_STAFF_ROLES_PERMISSIONS = gql(`
  mutation UpdateManyStaffRolesPermissions(    
    $input: UpdateManyStaffRolesPermissionsInput!
  ){ 
    updateManyStaffRolesPermissions(input: $input)
  }
`)

export const CREATE_MANY_STAFF_JOBS = gql(`
  mutation CreateManyStaffJobs($inputs: [StaffJobInput!]!) {
    createManyStaffJobs(inputs: $inputs) {
      _id
      uniqueId
    }
  }
`)

export const GET_STAFF_JOB_DETAIL = gql(`
  query GetStaffJobDetail($filter: StaffJobDetailFilterInput!) {
    getStaffJobDetail(filter: $filter) {
      ...staffJobDetail
    }
  }
`)

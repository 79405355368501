import { gql } from 'graphql-generator/__generated__/gql'

export const GET_ORDER_SOURCES = gql(`
  query GetOrderSources(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: OrdersSourceFilterInput
  ) {
    orderSources(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort, filter: $filter) {
      ...orderSource
    }
  }
`)

export const GET_LEAN_ORDER_SOURCES = gql(`
  query GetLeanOrderSources(
    $paginationOffset: Float
    $paginationLimit: Float
    $sort: SortInput
    $filter: OrdersSourceFilterInput
  ) {
    orderSources(paginationOffset: $paginationOffset, paginationLimit: $paginationLimit, sort: $sort, filter: $filter) {
      ...leanOrderSource
    }
  }
`)

export const GET_ORDER_SOURCES_META = gql(`
  query GetOrderSourcesMeta {
    orderSourcesMeta {
      all
      marketplace
      manual
    }
  }
`)

export const CREATE_ORDER_SOURCE = gql(`
  mutation CreateOrderSource($input: OrderSourceInput!) {
    createOrderSource(input: $input) {
      _id
      name
    }
  }
`)

export const INSERT_ORDER_SOURCES = gql(`
  mutation InsertOrderSources($inputs: [OrderSourceInput!]!) {
    insertOrderSources(inputs: $inputs) {
      _id
      name
    }
  }
`)

export const UPDATE_ORDER_SOURCE = gql(`
  mutation UpdateOrderSource($id: String!, $input: OrderSourceInput!) {
    updateOrderSource(id: $id, input: $input) {
      _id
      name
    }
  }
`)

export const DELETE_ORDER_SOURCE = gql(`
  mutation DeleteOrderSource($filter: OrdersSourceFilterInput!) {
    deleteOrderSource(filter: $filter) {
      _id
      name
    }
  }
`)
